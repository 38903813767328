.new-main-content {
    color: $black-color1;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;

    @include respond-min(tablet) {
        font-size: 15px;
    }
    h1 {
        font-size: 26px;
        font-weight: 700;
        line-height: 120%;
        margin: 0;

        @include respond-min(tablet) {
            font-size: 56px;
        }
    }
    h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: 130%;

        @include respond-min(tablet) {
            font-size: 40px;
        }
    }
    h3 {
        font-size: 22px;
        font-weight: 700;
        line-height: 140%;

        @include respond-min(tablet) {
            font-size: 28px;
        }
    }
    .ul-long-marker  {

        li {
            list-style: none;
            position: relative;
            margin-left: 26px;
            margin-bottom: 5px;

            @include respond-min(tablet) {
                margin-left: 28px;
            }
            &::before {
                position: absolute;
                content: '';
                top: 10px;
                left: -26px;
                background: $red;

                width: 14px;
                height: 2px;
                border-radius: 10px;

                @include respond-min(tablet) {
                    width: 20px;
                }
            }
        }
    }
    /*по моему здесь уже не какая то типография но пока не придумал куда ещё вынести
    такие стандартные стили нового дизайна, да и не накопилось много*/
    .red-button {
        text-decoration: none;
        cursor: pointer;
        color: $white;
        background: $red;
        font-size: 14px;
        font-weight: 600;
        line-height: 130%;
        letter-spacing: 0.14px;
        display: flex;
        padding: 14px 28px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        width: fit-content;
        grid-gap: 8px;

        &:hover {
            background: $red2;
        }
        svg {
            width: 19px;
            height: 19px;
        }
        &--full-width {
            width: 100%;
        }
    }
    section {
        margin-bottom: 60px;

        @include respond-min(tablet-sm) {
            margin-bottom: 100px !important;
        }
        &:last-of-type {
            margin-bottom: 90px;

            @include respond-min(tablet) {
                margin-bottom: 120px;
            }
        }
    }
    .link-to-chapter {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: $black-color1;
        font-size: 13px;
        font-weight: 600;
        line-height: 130%;
        letter-spacing: 0.13px;

        svg {
            background: $white;
            border-radius: 50%;
            height: 35px;
            margin-right: 10px;
            padding: 7px;
            width: 35px;
            flex-basis: 35px;
            flex-shrink: 0;

            @include respond-min(tablet) {
                height: 30px;
                margin-right: 15px;
                width: 30px;
                flex-basis: 30px;
                flex-shrink: 0;
            }
        }
        &:hover {

            svg {
                background: $red;

                use {
                    filter: invert(100%);
                }
            }
        }
    }
    .accordion {

        &__item {

        }
        .accordion__title {
            font-size: 20px;
            font-weight: 700;
            line-height: 140%;

            &::before {
                transform: translateX(-3px) translateY(-50%) translateZ(0) rotate(-135deg);
                opacity: 1;
                width: 10px;
            }
            &::after {
                transform: translateX(3px) translateY(-50%) translateZ(0) rotate(-45deg);
                opacity: 1;
                width: 10px;
            }
        }
        .active .accordion__title {

            &::before {
                transform: translateX(-3px) translateY(-50%) translateZ(0) rotate(-45deg);
                opacity: 1;
                background-color:  $red;
            }
            &::after {
                transform: translateX(3px) translateY(-50%) translateZ(0) rotate(-135deg);
                opacity: 1;
                background-color:  $red;
            }
        }
        &__content {

        }
    }
}
