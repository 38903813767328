/*временно, потом приведу в порядок, когда буду убирать файлы new*/
.page--about {
  .header {
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    flex-direction: column;
    gap: 14px;
    @include respond-min(tablet-sm) {
      gap: 0;
    }
    &-wrapper {
      position: fixed;
      z-index: 4;
      top: 0;
      left: 0;
      right: 0;
      flex-direction: column;
      transition-property: transform, background-color;
      -webkit-overflow-scrolling: touch;
      &__banner {
        font-family: 'Roboto',sans-serif;
        font-style: normal;
        font-weight: normal;
        color: #fff;
        text-align: center;
        padding: 12px;
        font-size: 13px;
        line-height: 125%;
        @include respond-min(tablet) {
          font-size: 18px;
          line-height: 33px;
          padding: 5px;
        }
      }
    }
  
    @include respond-min(tablet) {
      overflow: visible;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding-top: 0;
      padding-left: 3.75rem;
      padding-right: 3.75rem;
      background-color: $white;
      border-bottom: 1px solid rgba($black, 0.5);
      &--title {
        & .logo__small-img {
          display: flex;
        }
        & .logo__img {
          display: none;
        }
      }
  
      &--title {
        & .car-button__img:hover svg {
          fill: #dd044b;
        }
        & .car-button__img svg {
          width: 3.5rem;
          height: 2.75rem;
          fill: #ad033b;
        }
      }
  
      &--scrolled {
        transform: translateY(-3rem);
      }
  
      &::before {
        content: '';
        position: absolute;
        top: calc(3rem - 1px);
        left: 0;
        right: 0;
        height: 1px;
        background-color: rgba($black, 0.5);
      }
    }
  
    &__lang-switch {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.08em;
      
      a {
        color: $black-color1;
        text-decoration: none;
      }
      &--in-burger {
        margin-top: 10px;
        font-family: Roboto Bold,sans-serif;
        font-size: 1.5rem;
        font-weight: 700;
        letter-spacing: .08em;
        padding: 0;
        text-transform: uppercase;
  
        a {
          text-decoration: none;
        }
      }
      &--desktop {
        display: none;
  
        @include respond-min(tablet) {
          display: block;
        }
      }
      &--mobile {
        display: block;
        
        @include respond-min(tablet) {
          display: none;
        }
      }
    }
  
    @include respond-min(desktop) {
      padding-left: calc(50vw - #{$desktop} / 2 + 3.75rem);
      padding-right: calc(50vw - #{$desktop} / 2 + 3.75rem);
    }
  
    .scroll--header & {
      position: relative;
    }
    &.opened {
      overflow-y: auto;
      bottom: 0;
      height: 100vh;
      padding-bottom: 7.5rem;
  
      .header__mobileapp {
        display: block;
        margin: 4rem 2.5rem 0;
      }
    }
  
    .header__mobileapp {
      @include respond-min(tablet) {
        display: none;
      }
  
      a {
        font-size: 1.5rem;
        letter-spacing: 1px;
        text-decoration: none;
      }
    }
  
    &--scrolled {
      transform: translateY(-100%);
    }
  
    & .logo__small-img {
      display: none;
    }
  
    &__title.title {
      display: none;
  
      .buttons {
        display: flex;
        .buttons__invest {
          align-self: center;
        }
        .buttons__question {
          margin-right: 1.4em;
          .btn__circle {
            width: 2.38375rem;
            height: 2.38375rem;
          }
        }
      }
  
      @include respond-min(tablet) {
        .header.header--title & {
          display: flex;
          align-self: stretch;
          margin-right: 20px;
          flex-direction: row;
          flex-shrink: 0;
          align-items: center;
          justify-content: space-between;
          z-index: 1;
          flex-grow: 1;
  
          & h1 {
            margin-left: 1em;
            font-size: 1.4em;
            font-weight: bold;
          }
          & .products__btn {
            align-items: center;
            margin: auto;
          }
  
          & .files {
            padding: 0;
            & .files__list {
              flex-direction: row;
              & .files__item {
                margin-bottom: 0;
                & .files__link {
                  padding-left: .7em;
                  padding-right: .7em;
                  flex-direction: row;
                  & .files__file-icon {
                    width: 1.7rem;
                    height: 2.75rem;
                  }
                  & .files__file-title {
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    color: #000;
                    flex-direction: column;
                    padding-left: .4em;
                    //& .files__name {
                    //  margin-bottom: 0.225rem;
                    //}
                  }
                  & .files__file-title .files__name {
                    border-bottom: 1px solid transparent;
                  }
                  &:hover .files__file-title .files__name {
                    border-bottom: 1px solid #000;
                  }
                }
              }
            }
          }
        }
      }
    }
  
    &__overlay {
      position: fixed;
      z-index: 3;
      top: -50vh;
      left: 0;
      right: 0;
      height: 0;
      background-color: $white;
  
      .header.opened & {
        z-index: -1;
        height: 200vh;
      }
    }
  
    &__logo {
      position: sticky;
      z-index: 3;
      top: 0;
      left: 0;
      flex-shrink: 0;
      width: 100%;
      padding-top: 2rem;
      padding-bottom: 2rem;
      padding-left: 2.5rem;
      padding-right: 1.5rem;
      border-bottom: 1px solid $black;
  
      @include respond-min(tablet) {
        position: static;
        width: auto;
        padding: 1.5rem 0;
        border-bottom: 0;
  
        .header--title & {
          width: 3.5rem;
          padding: 1rem 0;
        }
      }
    }
  
    &__social {
      overflow: hidden;
      position: relative;
      display: flex;
      align-self: flex-start;
      flex-shrink: 0;
      margin-left: $offset-mobile;
      margin-right: $offset-mobile;
  
      .header.opened & {
        transition-delay: 0.7s;
      }
  
      .header--title & {
        display: none;
      }
  
      a {
        @include font('m');
  
        font-size: 2rem;
        letter-spacing: 0.08em;
        text-align: left;
      }
  
      @include respond-min(tablet) {
        order: 5;
        display: flex;
        justify-content: flex-end;
        align-self: auto;
        margin-left: 7.75rem;
        margin-right: 0;
  
        a {
          font-size: 1rem;
          letter-spacing: 0.08em;
        }
      }
    }
    .header__mobileapp {
      display: none;
      margin: 4rem 2.5rem 0;
  
      @include respond-min(tablet) {
        display: none;
      }
  
      a {
        font-size: 1.5rem;
        letter-spacing: 1px;
        text-decoration: none;
      }
    }
    &.opened .header__mobileapp {
      display: block;
    }
    .mobileapp__icons {
      display: flex;
      align-items: flex-end;
      margin-top: 20px;
      grid-gap: 15px
    }
  
    &__profile {
      display: none;
  
      .header.opened & {
        display: flex;
      }
  
      @include respond-min(tablet) {
        display: flex;
  
        .header--title & {
          display: none !important;
        }
      }
    }
  }
  
  .header-sidenav {
    display: flex;
    flex-direction: column;
  
    @include respond-min(tablet) {
      flex-direction: row;
    }
  }
  
  .aside-navigation {
    background-color: $l-grey;
    color: $black;
    margin-top: 4rem;
    padding-top: 0;
    padding-right: 1rem;
    position: relative;
    overflow: hidden;
    height: 40px;
  
    @include respond-min(tablet) {
      width: 25%;
      background-color: $white;
      padding-top: 4rem;
      overflow: visible;
      height: auto;
    }
  
    &.active {
      overflow: visible;
      height: auto;
    }
    ul {
      display: flex;
      flex-direction: column;
    }
  }
  
  .toggle-navigation {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
  
    &__arrow {
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 10px solid $red;
      position: absolute;
      right: 10px;
      top: 15px;
    }
  
    .aside-navigation.active & {
      display: none;
    }
  
    @include respond-min(tablet) {
      display: none;
    }
  }
  
  .aside-content-header {
  
    @include respond-min(tablet) {
      width: 75%;
    }
  }
  
  .aside-nav {
    &__link {
      font-family: 'Roboto Medium';
      font-size: 12px;
      line-height: 1.2;
      display: block;
      color: $text-color;
      margin-bottom: 0.3rem;
      margin-right: 1.3rem;
      padding: 10px;
      text-decoration: none;
      border-radius: 4px;
      position: relative;
      order: 2;
  
      @include respond-min(tablet) {
        font-size: 1rem;
      }
  
      &:hover, &.active {
        background-color: $l-grey;
        color: $black;
        @include respond-min(tablet) {
          .aside-nav__triangle {
            display: block;
          }
        }
      }
      @include respond-max(tablet) {
        &.active {
          order: 1;
        }
      }
    }
    &__triangle {
      display: none;
      position: absolute;
      width: 58px;
      height: 58px;
      top:0;
      right: -45px;
      overflow: hidden;
  
      &::after {
        content: '';
        display: block;
        transform: rotate(45deg);
        background-color: $l-grey;
        width: 100%;
        height: 100%;
        top: 0;
        left: -22px;
        position:relative;
        border-radius: 4px;
      }
    }
  }
}
.new-header {
  .only-mobile {
    @include respond-min(tablet-sm) {
      display: flex !important;
    }

    @include respond-min(desktop-sm) {
      display: none !important;
    }
  }
  .only-desktop {
    @include respond-min(tablet-sm) {
      display: none !important;
    }

    @include respond-min(desktop-sm) {
      display: flex !important;
    }
  }
  .header {
    display: flex;
    justify-content: flex-start !important;
    overflow: hidden;
    flex-direction: column !important;
    border-bottom: 0;
    box-shadow: 0px 10px 80px rgba(0, 0, 0, 0.09);
    align-items: baseline !important;
    background-color: $white;

    @include respond-min(tablet-sm) {
      padding: 14px 16px;
    }

    @include respond-min(desktop-sm) {
      padding: 0 32px !important;
      gap: 24px;
      overflow: visible;
      flex-direction: row !important;
      flex-wrap: wrap;
      justify-content: space-between !important;
      align-items: center !important;
      border-bottom: 0;
      margin-bottom: -2px;

      &--title {
        & .logo__small-img {
          display: flex;
        }
        & .logo__img {
          display: none;
        }
      }

      &--title {
        & .car-button__img:hover svg {
          fill: $l-red1;
        }
        & .car-button__img svg {
          width: 3.5rem;
          height: 2.75rem;
          fill: $red;
        }
      }

      &--scrolled {
        transform: translateY(-3rem);
      }

      &::before {
        content: '';
        position: absolute;
        top: calc(3rem - 1px);
        left: 0;
        right: 0;
        height: 1px;
        background-color: rgba($black, 0.5);
      }
    }

    @include respond-min(desktop-lg) {
      padding: 0 40px !important;
      gap: 32px;
    }

    &-wrapper {
      position: fixed;
      z-index: 4;
      top: 0;
      left: 0;
      right: 0;
      flex-direction: column;
      transition-property: transform, background-color;
      -webkit-overflow-scrolling: touch;
      font-family: 'Montserrat', sans-serif;
      font-size: 3.5vw;
      line-height: 1.5;

      @include respond-min(tablet-sm) {
        font-size: 1.75vw;
      }

      @include respond-min(tablet-sm) {
        font-size: .825vw !important;
      }

      &__banner {
        font-family: 'Roboto',sans-serif;
        font-style: normal;
        font-weight: normal;
        color: $white;
        text-align: center;
        padding: 12px;
        font-size: 13px;
        line-height: 125%;
        @include respond-min(tablet-sm) {
          font-size: 18px;
          line-height: 33px;
          padding: 5px;
        }
        > a {
          color: $white;
        }
      }
    }

    .scroll--header & {
      position: relative;
    }
    &.opened {
      overflow-y: auto;
      bottom: 0;
      height: 100vh;
      padding-bottom: 7.5rem;

      @include respond-min(tablet-sm) {
        padding-top: 0;
        padding-bottom: 0;
      }

      .header__logo {

        @include respond-min(tablet-sm) {
          height: 50px;
          padding-top: 16px !important;
        }
      }
      
      .header__mobileapp {
        display: block;
        font-family: 'Montserrat', sans-serif;
        margin: 4rem 2.5rem 0;
      }
      
      .nav-menu__container {
      
        @include respond-min(tablet-sm) {
          padding: 24px 0;
        }
      }
    }

    .nav-menu__container {
      grid-column-gap: 2em;
      grid-row-gap: 2em;
      justify-content: end;
      align-items: stretch;
      display: flex;

      @include respond-min(desktop-sm) {
        flex: 1;
        justify-content: space-between;
      }
    }

    .header__mobileapp {
      @include respond-min(tablet-sm) {
        display: none;
      }

      a {
        font-size: 1.5rem;
        letter-spacing: 1px;
        text-decoration: none;
      }
    }

    &--scrolled {
      transform: translateY(-100%);
    }

    & .logo__small-img {
      display: none;
    }

    &__title.title {
      display: none;

      .buttons {
        display: flex;
        .buttons__invest {
          align-self: center;
        }
        .buttons__question {
          margin-right: 1.4em;
          .btn__circle {
            width: 2.38375rem;
            height: 2.38375rem;
          }
        }
      }

      @include respond-min(tablet-sm) {
        .header.header--title & {
          display: flex;
          align-self: stretch;
          margin-right: auto;
          flex-direction: row;
          flex-shrink: 0;
          align-items: center;
          justify-content: space-between;
          z-index: 1;
          flex-grow: 1;

          & h1 {
            margin-left: 1em;
            font-size: 1.4em;
            font-weight: bold;
          }
          & .products__btn {
            align-items: center;
            margin: auto;
          }

          & .files {
            padding: 0;
            & .files__list {
              flex-direction: row;
              & .files__item {
                margin-bottom: 0;
                & .files__link {
                  padding-left: .7em;
                  padding-right: .7em;
                  flex-direction: row;
                  & .files__file-icon {
                    width: 1.7rem;
                    height: 2.75rem;
                  }
                  & .files__file-title {
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    color: #000;
                    flex-direction: column;
                    padding-left: .4em;
                    //& .files__name {
                    //  margin-bottom: 0.225rem;
                    //}
                  }
                  & .files__file-title .files__name {
                    border-bottom: 1px solid transparent;
                  }
                  &:hover .files__file-title .files__name {
                    border-bottom: 1px solid #000;
                  }
                }
              }
            }
          }
        }
      }
    }

    &__overlay {
      position: fixed;
      z-index: 3;
      top: -50vh;
      left: 0;
      right: 0;
      height: 0;
      background-color: $white;

      .header.opened & {
        z-index: -1;
        height: 200vh;
      }
    }

    &__logo {
      position: sticky;
      z-index: 3;
      top: 0;
      left: 0;
      flex-shrink: 0;
      width: 100% !important;
      padding: 0 1em 0 !important;
      background-color: $white;
      grid-column-gap: 1.5em;
      grid-row-gap: 1.5em;
      justify-content: space-between;
      align-self: baseline;
      border-bottom: 0 !important;

      @include respond-min(tablet-sm) {
        height: 34px;
      }

      @include respond-min(desktop-sm) {
        position: static;
        align-self: center;
        width: 132px !important;
        height: 36px;
        padding: 0 !important;

        .header--title & {
          width: 3.5rem;
          padding: 1rem 0;
        }

        .logo__link {
          margin-right: 0;
          padding-bottom: 0;
          padding-top: 0;
        }
      }

      @include respond-min(desktop) {
        width: 144px !important;
        height: 40px !important;
      }

      @include respond-min(desktop-xlg) {
        width: 160px !important;
        height: 44px !important;
      }

      .mobile-right {
        display: flex;
        align-items: center;
        gap: 36px;

        @include respond-min(tablet-sm) {
          gap: 34px;
        }
      }
      
      .logo__link {
        margin-right: 0;
        align-self: center;
        padding-bottom: 15px;
        padding-top: 15px;
        width: 104px;

        @include respond-min(tablet-sm) {
          width: 120px;
        }

        @include respond-min(desktop-sm) {
          width: 132px;
          height: 36px;
        }

        @include respond-min(desktop) {
          width: 144px !important;
          height: 40px !important;
        }
  
        @include respond-min(desktop-xlg) {
          width: 160px !important;
          height: 44px !important;
        }

        .logo__img {
          svg {
            height: 30px;
            width: 104px;

            @include respond-min(tablet-sm) {
              width: 120px;
              height: 34px;
            }

            @include respond-min(desktop-sm) {
              width: 132px;
              height: 36px;
            }
    
            @include respond-min(desktop) {
              width: 144px !important;
              height: 40px !important;
            }
      
            @include respond-min(desktop-xlg) {
              width: 160px !important;
              height: 44px !important;
            }
          }
        }
      }
      .logo__img svg {

        /*@include respond-min(tablet-sm) {
          width: 100px;
          height: 4rem;
        }*/
        @include respond-min(desktop) {
          width: 9rem;
          height: 2.25rem;
        }
      }
    }

    &__social {
      overflow: hidden;
      position: relative;
      display: flex;
      align-self: flex-start;
      flex-shrink: 0;
      margin-left: $offset-mobile;
      margin-right: $offset-mobile;

      .header.opened & {
        transition-delay: 0.7s;
      }

      .header--title & {
        display: none;
      }

      a {
        @include font('m');

        font-size: 2rem;
        letter-spacing: 0.08em;
        text-align: left;
      }

      @include respond-min(tablet-sm) {
        order: 5;
        display: flex;
        justify-content: flex-end;
        align-self: auto;
        margin-left: 7.75rem;
        margin-right: 0;

        a {
          font-size: 1rem;
          letter-spacing: 0.08em;
        }
      }

        .info-content__socials {
          width: 100%;
          margin-bottom: 15px;

          @include respond-min(tablet-sm) {
            margin-bottom: 0;
          }
          .btn__circle {
            width: 45px;
            height: 45px;

            @include respond-min(tablet-sm) {
              width: 34px;
              height: 34px;
            }
            &::after {
              background: #EAEAEB;
              border: 0;
            }
            svg {
              z-index: 1;
              fill: #898B8F;
            }
            .info-content__social-image {
              height: 50%;
              width: 50%;
            }
          }
        }
    }
    .header__mobileapp {
      display: none;
      margin: 4rem 2.5rem 0;

      @include respond-min(tablet-sm) {
        display: none;
      }

      a {
        font-size: 1.5rem;
        letter-spacing: 1px;
        text-decoration: none;
      }
    }
    &.opened .header__mobileapp {
      display: block;
    }
    .mobileapp__icons {
      display: flex;
      align-items: flex-end;

      a {
        background: #898B8F;
        border-radius: 6px;
      }
    }

    &__profile {
      display: none;

      @include respond-min(tablet-sm) {
        display: flex;

        .header--title & {
          display: none !important;
        }
      }
    }
    .user-nav__link {
      font-family: 'Montserrat', sans-serif;
      font-size: 1.85rem;
      line-height: 1.4;
      font-weight: 600;

      @include respond-min(tablet-sm) {
        font-size: 1em;
        text-transform: none;
        letter-spacing: normal;
      }
    }
    .user-nav__item {
      .profile__icon {
        margin-right: 1rem;

        svg{
          width: 25px;
          height: 25px;
        }
      }
    }
    .lk-nav-ol_button {
      margin-left: 0;
      margin-right: 0;
      opacity: .75;
      padding: 0;
      transition: opacity .2s, background-color .2s;
      font-size: 1em;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.75em;
      height: 2.75em;
      &:hover {
        background-color: #f3f3f3;
        opacity: 1;
        color: #5c6e8a;
      }
    }
    .header__lang-switch {
      font-family: 'Montserrat', sans-serif;
      margin-top: 20px;

      @include respond-min(tablet-sm) {
        align-self: center;
        margin-left: 20px;
        margin-top: 0;
      }
    }
  }
}

.header-sidenav {
  display: flex;
  flex-direction: column;

  @include respond-min(tablet-sm) {
    flex-direction: row;
  }
}

.aside-navigation {
  background-color: $l-grey;
  color: $black;
  margin-top: 4rem;
  padding-top: 0;
  padding-right: 1rem;
  position: relative;
  overflow: hidden;
  height: 40px;

  @include respond-min(tablet-sm) {
    width: 25%;
    background-color: $white;
    padding-top: 4rem;
    overflow: visible;
    height: auto;
  }

  &.active {
    overflow: visible;
    height: auto;
  }
  ul {
    display: flex;
    flex-direction: column;
  }
}

.toggle-navigation {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;

  &__arrow {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 10px solid $red;
    position: absolute;
    right: 10px;
    top: 15px;
  }

  .aside-navigation.active & {
    display: none;
  }

  @include respond-min(tablet-sm) {
    display: none;
  }
}

.aside-content-header {

  @include respond-min(tablet-sm) {
    width: 75%;
  }
}

.aside-nav {
  &__link {
    font-family: 'Roboto Medium';
    font-size: 12px;
    line-height: 1.2;
    display: block;
    color: $text-color;
    margin-bottom: 0.3rem;
    margin-right: 1.3rem;
    padding: 10px;
    text-decoration: none;
    border-radius: 4px;
    position: relative;
    order: 2;
    height: 58px;

    @include respond-min(tablet-sm) {
      font-size: 1rem;
    }

    &:hover, &.active {
      background-color: $l-grey;
      color: $black;
      @include respond-min(tablet-sm) {
        .aside-nav__triangle {
          display: block;
        }
      }
    }
    @include respond-max(tablet-sm) {
      &.active {
        order: 1;
      }
    }
  }
  &__triangle {
    display: none;
    position: absolute;
    width: 58px;
    height: 58px;
    top:0;
    right: -45px;
    overflow: hidden;

    &::after {
      content: '';
      display: block;
      transform: rotate(45deg);
      background-color: $l-grey;
      width: 100%;
      height: 100%;
      top: 0;
      left: -22px;
      position:relative;
      border-radius: 4px;
    }
  }
}
.header__block-phones {
  display: none !important;
  flex-direction: column;
  gap: 64px;
  padding: 8px 16px;
    
  @include respond-min(tablet-sm) {
    padding: 0;
    margin-bottom: 126px;
  }
    
  @include respond-min(desktop-sm) {
    padding: 0;
    margin-bottom: unset;
  }

  .opened & {
    display: flex !important;
  }
  .social-container {
    gap: 20px;
    align-items: center;
    display: flex;
    
    @include respond-min(tablet-sm) {
      display: none;
    }

    .social-button {
      background-color: #0E0F1105;
      opacity: 1;
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: 20px;
      padding: 0;
      display: block;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      transition: opacity .2s, background-color .2s;

      &.call {
        background-image: url("../../../src/assets/sprite/i-menu-phone.svg");
      }
      &.search {
        background-image: url("../../../src/assets/sprite/i-search-new.svg");
      }
      &.telegram {
        background-image: url("../../../src/assets/sprite/i-telegram-icon.svg");
      }
      &.en {
        justify-content: center;
        align-items: center;
        display: flex;
        text-decoration: none;
        color: #0E0F1180;
        font-family: 'Montserrat', sans-serif;
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        text-transform: uppercase;
      }
    }
  }
  .login-container {
    gap: 16px;
    display: flex !important;
    flex-direction: column;
    
    @include respond-min(tablet-sm) {
      width: 220px;
      gap: 8px;
    }

    a {
      padding: 10px 12px;
      background: #0E0F1105;
      border: 1px solid #0E0F110D;
      border-radius: 8px;
      gap: 4px;
      align-items: baseline;
      flex: 1;
      display: flex;
      text-decoration: none;

      .login-text {
        flex-flow: column;
        display: flex;
        flex: 1;
        gap: 4px;
        padding: 0;
        align-items: flex-start;

        .text-header {
          font-family: 'Montserrat', sans-serif;
          font-size: 13px;
          font-weight: 600;
          line-height: 18px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #0C0E11E5;
          letter-spacing: -0.005em;
        }

        .text-description {
          font-family: 'Montserrat', sans-serif;
          font-size: 11px;
          font-weight: 500;
          line-height: 15px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #0E0F11B2;
          letter-spacing: -0.005em;
        }
      }
      .login-icon {
        width: 20px;
        height: 20px;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
.modal__container-lk {
  background: #F1F5F9;
  border-radius: 16px;
  max-width: 640px;
  padding: 24px 32px;
  @include respond-min(tablet-sm) {
    padding: 48px 64px;
  }

  .modal__header {
    .modal__title-lk {
      font-family: 'Montserrat', sans-serif;
      font-size: 24px;
      font-weight: 600;
      line-height: 31px;
      text-align: left;
    }
  }
  .modal__content {
    .open-lk {
      &__ref {
        display: flex;
        align-items: center;
        text-decoration: none;
        border: 1px solid #DDE2E9;
        background: #FFFFFF;
        padding: 16px;
        border-radius: 8px;
        margin-bottom: 16px;
        .open-text {
          font-family: 'Montserrat', sans-serif;
          font-size: 18px;
          font-weight: 500;
          line-height: 25px;
          text-align: center;
          color: #12161CBF;
          margin-left: 24px;
        }
      }
      &__button {
        width: 64px;
        height: 64px;
        padding: 8px;
        border-radius: 8px;
        background: #F0F2F5;
      }
    }
  }
}