/*временно, потом приведу в порядок, когда буду убирать файлы new*/
.footer {
  padding-top: 5rem;
  border-top: 1px solid $black;
  .footer__top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 5rem;
    padding-bottom: 3rem;
    border-bottom: 2px solid $black;
  
    @include respond-min(tablet) {
      flex-direction: row;
      align-items: center;
      margin-bottom: 3.125rem;
    }
  
    .footer__logo {
      width: 61%;
      margin-bottom: 5rem;
  
      @include respond-min(tablet) {
        margin-bottom: 0;
        width: auto;
      }
  
      svg {
        max-width: 24.5rem;
        height: 6.9rem;
  
        @include respond-min(tablet) {
          max-width: 16.25rem;
          height: 4.54rem;
        }
      }
    }
    .phones-block {
      flex-direction: column;
      align-items: baseline;
      width: auto;
      display: flex;
      justify-content: space-between;
  
      @include respond-min(tablet) {
        flex-direction: row;
        align-items: center;
        width: 39%;
      }
  
      &__item {
        margin-bottom: 1.715rem;
        line-height: 3rem;
  
        @include respond-min(tablet) {
          line-height: 1.5rem;
          margin-bottom: 0;
        }
  
        .phone-number {
          font-weight: 600;
          font-size: 2.6rem;
          line-height: 3.8rem;
  
          @include respond-min(tablet) {
            font-size: 1.3rem;
            line-height: 1.9rem;
          }
        }
  
        .phone-caption {
          font-size: 1.6rem;
          color: #75808F;
  
          @include respond-min(tablet) {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
  
  .footer__nav {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5rem;
  
    @include respond-min(tablet) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  
  .footer__nav-block {
    display: flex;
    flex-direction: column;
    width: 48%;
    margin-top: 5rem;
    margin-right: 4%;
  
    &:nth-of-type(-n + 2) {
      margin-top: 0;
    }
  
    &:nth-of-type(2n) {
      margin-right: 0;
    }
  
    @include respond-min(tablet) {
      width: auto;
      margin-top: 0;
      margin-right: 0;
    }
  }
  
  .footer__nav-title {
    margin-bottom: 2.5rem;
  
    @include respond-min(tablet) {
      margin-bottom: 1.875rem;
      letter-spacing: 0.12em;
    }
  }
  
  .footer__nav-list {
    display: flex;
    flex-direction: column;
  }
  
  .footer__nav-item {
    display: flex;
  }
  
  .footer__nav-link {
    font-size: 1.375rem;
    line-height: 1.76;
    letter-spacing: 0.02em;
  
    @include respond-min(tablet) {
      font-size: 0.875rem;
      line-height: 2;
    }
  }
  .footer__icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5rem;
  
    @include respond-max(tablet) {
      justify-content: center;
    }
  }
  
  .footer__bottom {
    //@include bg-responsive('footer-bg-desktop', 'footer-bg-mobile', '1024px');
    background-image: url("/assets/dist/img/footer-bg-desktop@1x.png");
    display: flex;
    flex-direction: column;
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-position: right;
    background-size: cover;
  
    @include respond-min(tablet) {
      padding-top: 3.125rem;
      padding-bottom: 3.125rem;
    }
  }
  
  .footer__disclaimers {
    display: flex;
    flex-direction: column;
    margin-bottom: 5rem;
    padding-bottom: 5rem;
    border-bottom: 1px solid $white;
  
    @include respond-min(tablet) {
      flex-direction: row;
      margin-bottom: 3.125rem;
      padding-bottom: 3.75rem;
    }
  
  }
  
  .footer__disclaimer {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
  
    & a:focus, & a:hover {
      font-weight: bold;
    }
  
    &:last-of-type {
      margin-bottom: 0;
    }
  
    & .footer__disclaimer_inner.desktop {
      display: none;
    }
  
    & .footer__disclaimer_under {
      margin: 20px 0;
      flex-direction: column;
      p {
        font-size: 1.8rem;
        @include respond-min(tablet) {
          font-size: 1rem;
        }
      }
    }
  
    p {
      margin-bottom: 1.5rem;
      font-size: 1.125rem;
      line-height: 1.2;
      color: rgba($white, 0.5);
  
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  
    @include respond-min(tablet) {
      width: 100%;
      margin-bottom: 0;
      padding-right: 2.5rem;
  
      .footer__disclaimer_inner.desktop {
        display: block;
        column-count: 2;
      }
  
      p {
        margin-bottom: 1rem;
        font-size: 0.625rem;
        letter-spacing: 1px;
      }
    }
  }
  
  .footer__copyrights {
    display: flex;
    flex-direction: column;
  
    @include respond-min(tablet) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  
  .footer__cookiemsg {
    border-top: 1px solid #fff;
    margin-top: 3em;
    padding-top: 3em;
    & p {
      margin-bottom: 1.5rem;
      font-size: 1.125rem;
      line-height: 1.2;
      color: hsla(0,0%,100%,.5);
  
      &:last-child {
        margin-bottom: 0;
      }
  
      @include respond-min(tablet) {
        font-size: .625rem;
        letter-spacing: 1px;
        color: hsla(0,0%,100%,.5);
      }
    }
    & a:focus, & a:hover {
      font-weight: bold;
    }
  }
  
  .info-content__mobileapp {
    align-items: flex-end;
  
    a {
      margin-right: 1rem;
      text-decoration: none;
      font-size: .875rem;
      line-height: 1.4;
    }
    span {
      margin-right: 1rem;
    }
  }
  
  .footer__copyright {
    margin-bottom: 1.5rem;
  
    &:last-of-type {
      margin-bottom: 0;
    }
  
    &--its {
      a {
        &:hover,
        &:focus {
          color: $red;
        }
      }
    }
  
    @include respond-min(tablet) {
      margin-bottom: 0;
  
      @include respond-min(tablet) {
        &--its {
          text-transform: none;
        }
      }
    }
  }
  
  
  .cookiemsg {
    display:none;
    position: fixed;
    bottom: 0em;
    font-size: 0.8em;
    background-color: hsla(0,0%,100%,.95);
    padding: 1em 16%;
    z-index: 3;
    border-top: 1px solid rgb(237, 241, 242);
  
    @include respond-min(tablet) {
      left: 22px;
      bottom: 22px;
      width: 523px;
      padding: 10px 57px 10px 24px;
      box-shadow: 0 0 48px rgba(17,56,86,.2);
      border: none;
    }
  
    & a {
      color: #551a8f;
    }
    & a:focus, & a:hover {
      font-weight: bold;
    }
  
    & .cookiemsg__text {
      padding: 1em 0;
      font-size: 1.6em;
      position: relative;
  
      @include respond-min(tablet) {
        padding: 2em 0;
        font-size: 1em;
      }
  
      & .cookiemsg__bottom_close {
        position:absolute;
        cursor:pointer;
        width: 2em;
        height: 2em;
        @include respond-max(tablet) {
          left: -4.5rem;
          top: 0.5em;
        }
  
        @include respond-min(tablet) {
          right: -2.5rem;
          top: 1.5em;
        }
  
  
        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 0.5em;
          width: 14px;
          height: 1px;
          background-color: $black;
          transform: translateY(-50%) translateZ(0) rotate(-45deg);
          transition: $trs;
        }
  
        &:hover::before,
        &:hover::after {
          height: 2px;
        }
  
        &::after {
          transform: translateY(-50%) translateZ(0) rotate(45deg);
        }
  
      }
  
    }
  
  }
}
.footer-new {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #0C0E11;
  padding: 24px 16px;
  z-index: 1;
  position: relative;
  width: 100%;

  @include respond-min(tablet-sm) {
    padding: 40px 36px 24px;
  }

  @include respond-min(tablet-sl) {
    padding: 48px;
  }

  .cookiemsg {
    display:none;
    position: fixed;
    bottom: 0em;
    font-size: 0.8em;
    background-color: hsla(0,0%,100%,.95);
    padding: 1em 16%;
    z-index: 3;
    border-top: 1px solid rgb(237, 241, 242);

    @include respond-min(tablet) {
      left: 22px;
      bottom: 22px;
      width: 523px;
      padding: 10px 57px 10px 24px;
      box-shadow: 0 0 48px rgba(17,56,86,.2);
      border: none;
    }

    & a {
      color: #551a8f;
    }
    & a:focus, & a:hover {
      font-weight: bold;
    }

    & .cookiemsg__text {
      padding: 1em 0;
      font-size: 1.6em;
      position: relative;

      @include respond-min(tablet) {
        padding: 2em 0;
        font-size: 1em;
      }

      & .cookiemsg__bottom_close {
        position:absolute;
        cursor:pointer;
        width: 2em;
        height: 2em;
        @include respond-max(tablet) {
          left: -4.5rem;
          top: 0.5em;
        }

        @include respond-min(tablet) {
          right: -2.5rem;
          top: 1.5em;
        }


        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 0.5em;
          width: 14px;
          height: 1px;
          background-color: $black;
          transform: translateY(-50%) translateZ(0) rotate(-45deg);
          transition: $trs;
        }

        &:hover::before,
        &:hover::after {
          height: 2px;
        }

        &::after {
          transform: translateY(-50%) translateZ(0) rotate(45deg);
        }

      }

    }

  }

  .footer__alter-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 32px;
    width: 100%;

    @include respond-min(tablet-sm) {
      flex-direction: row;
      margin-bottom: 40px;
      max-width: 696px;
    }

    @include respond-min(tablet-sl) {
      margin-bottom: 32px;
      max-width: 912px;
    }

    @include respond-min(desktop-sm) {
      max-width: 1200px !important;
    }

    @include respond-min(desktop-lg) {
      max-width: 1440px !important;
    }

    .footer__top {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 36px;
      gap: 24px;

      @include respond-min(tablet-sm) {
        gap: 34px;
        min-width: 216px;
        width: 216px;
        margin-right: 24px;
      }

      @include respond-min(tablet-sl) {
        min-width: 288px;
        width: 288px;
      }

      @include respond-min(desktop-sm) {
        min-width: 220px;
        width: 220px;
      }

      @include respond-min(desktop-lg) {
        min-width: 270px;
        width: 270px;
      }
      
      .footer__logo {
        width: 100%;
        margin: 0;

        svg, img {
          max-width: 24.5rem;
          width: 120px;
        }
      }
      .phones-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        grid-gap: 16px;
        justify-content: space-between;

        &__item {
          margin: 0;
          display: flex;
          flex-direction: column;
          gap: 8px;
  
          .phone-number {
            font-family: 'Montserrat', sans-serif;
            font-size: 13px;
            font-weight: 500;
            line-height: 18px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #FFFFFFE5;
  
            @include respond-min(tablet-sm) {
              font-size: 15px;
              line-height: 20px;
              letter-spacing: -0.005em;
            }
          }
  
          .phone-caption {
            font-family: 'Montserrat', sans-serif;
            font-family: Montserrat;
            font-size: 10px;
            font-weight: 500;
            line-height: 14px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #FFFFFF99;
          }
        }
      }
      .info-content__socials {
        width: 100%;
        gap: 14px;
        margin: 0;
        
        .btn__circle {
          height: 28px;
          width: 28px;

          &:hover {
            opacity: 0.6;
          }

          &::after {
            background: #FFFFFF99;
            border: 0;
            height: 100%;
            width: 100%;
          }
          &.dzen-yt::after {
            background: 0;
          }
          &::before {
            &:hover {
              background: transparent !important;
            }
          }
          svg {
            z-index: 1;
          }
          .info-content__social-image {
            height: 50%;
            width: 50%;
            fill: #0C0E11E5;
            &.dzen-yt-btn {
              height: 100%;
              width: 100%;
            }
          }
        }
      }
    }

    .footer__nav {
      display: grid;
      grid-gap: 24px;
      margin: 0;

      @include respond-min(tablet-sm) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        grid-row-gap: 56px;
      }

      @include respond-min(tablet-sl) {
        grid-row-gap: 40px;
      }

      @include respond-min(desktop-sm) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 20px;
        grid-row-gap: unset;
      }

      .footer__nav-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0;
        gap: 16px;

        @include respond-min(tablet-sm) {
          gap: 20px;
        }

        .footer__nav-item {
          display: flex;
          opacity: 1;

          &:hover {
            opacity: 0.6;
          }
        }

        .footer__nav-title {
          .footer__nav-link {
            color: #FFFFFFE5 !important;
            font-weight: 600 !important;
          }
        }

        .footer__nav-list {
          display: flex;
          flex-direction: column;
          gap: 8px;

          @include respond-min(tablet-sm) {
            gap: 10px;
          }
      
          &--mobileapp {
            margin-top: 12px;

            .footer__nav-link {
              font-size: 10px !important;
              line-height: 14px !important;
              br {
                display: none;

                @include respond-min(tablet-sm) {
                  display: initial;
                }
              }
            }
          }

          &--icons {
            flex-direction: row;
            gap: 36px;

            @include respond-min(tablet-sm) {
              gap: 16px;
            }

            img {
              width: 40px;
              height: 40px;
            }
          }
        }
      
        .footer__nav-link {
          font-family: 'Montserrat', sans-serif;
          font-size: 13px;
          font-weight: 500;
          line-height: 18px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #FFFFFF99;
        }
      }
    }
  }
  
  .footer-container {
    width: 100%;

    @include respond-min(tablet-sm) {
      max-width: 696px;
    }

    @include respond-min(tablet-sl) {
      max-width: 912px;
    }

    @include respond-min(desktop-sm) {
      max-width: 1200px !important;
      display: flex;
      flex-direction: row-reverse;
      gap: 24px;
      justify-content: space-between;
    }

    @include respond-min(desktop-lg) {
      max-width: 1440px !important;
    }

    .bottom-info {
      @include respond-min(desktop-sm) {
        max-width: 220px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        min-width: 220px;
      }

      @include respond-min(desktop-lg) {
        min-width: 270px;
        width: 270px;
        max-width: 270px;
      }
    }
  }

  .footer__bottom {
    display: flex;
    flex-direction: column;

    @include respond-min(tablet-sm) {
      max-width: 696px;
    }

    @include respond-min(tablet-sl) {
      max-width: 912px;
    }

    @include respond-min(desktop-sm) {
      max-width: 1200px !important;
    }

    @include respond-min(desktop-lg) {
      max-width: 1440px !important;
    }

    .footer__disclaimers {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
      gap: 16px;

      @include respond-min(tablet-sm) {
        margin-bottom: 0;
        gap: 20px;
      }

      @include respond-min(tablet-sl) {
        gap: 24px;
      }
  
      .footer__disclaimer {
        display: flex;
        flex-direction: column;
        font-family: 'Montserrat', sans-serif;
        font-size: 11px;
        font-weight: 500;
        line-height: 15px;
        text-align: left;
        color: #FFFFFF99;
        
        &_inner {
          @include respond-min(desktop-sm) {
            display: block;
            column-count: 2;
            gap: 20px;
            font-size: 12px;
            line-height: 16px;
          }

          p {
            margin-bottom: 15px;

            @include respond-min(desktop-sm) {
              margin-bottom: 16px;
            }
          }
        }
        a {
          color: #FFFFFF99 !important;
          text-underline-position: from-font;
          text-decoration-skip-ink: auto;
          text-decoration-line: underline;
          text-decoration-style: solid;
        }
    
        & a:focus, & a:hover {
          font-weight: bold;
        }
    
        .tablet {
          display: none;

          @include respond-min(tablet-sm) {
            display: flex;
          }

          @include respond-min(desktop-sm) {
            display: none;
          }
        }
    
        .mobile {
          display: flex;
          
          @include respond-min(tablet-sm) {
            display: none;
          }
        }
    
        /*& .footer__disclaimer_under {
          margin: 20px 0;
          flex-direction: column;
          padding: 10px 14px;
          border-radius: 10px;
          border: 1px solid #FFFFFFE5;
          color: #FFFFFFE5;
        }*/
      }
    }
  }
  .footer__disclaimer_under {
    margin: 20px 0;
    padding: 10px 14px;
    border-radius: 10px;
    border: 1px solid #FFFFFF66;

    @include respond-min(tablet-sm) {
      margin: 9px 0 24px;
    }

    &.desktop {
      display: none;

      @include respond-min(desktop-sm) {
        display: flex;
        margin: 0 !important;
      }
    }

    p {
      font-family: 'Montserrat', sans-serif;
      font-size: 11px;
      font-weight: 500;
      line-height: 15px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #FFFFFFE5;

      @include respond-min(desktop-sm) {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
  
  .footer__cookiemsg {
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 500;
    line-height: 15px;
    text-align: left;
    color: #FFFFFF99;
    margin-bottom: 32px;
    width: 100%;

    @include respond-min(tablet-sm) {
      max-width: 696px;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 40px;
    }

    @include respond-min(tablet-sl) {
      max-width: 912px;
      margin-bottom: 32px;
    }

    @include respond-min(desktop-sm) {
      width: 100%;
    }
    
    a {
      color: #FFFFFF99 !important;
      text-underline-position: from-font;
      text-decoration-skip-ink: auto;
      text-decoration-line: underline;
      text-decoration-style: solid;
    }

    & a:focus, & a:hover {
      font-weight: bold;
    }
  }
  
  .footer__copyrights {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    width: 100%;

    @include respond-min(tablet-sm) {
      max-width: 696px;
    }

    @include respond-min(tablet-sl) {
      max-width: 912px;
    }

    @include respond-min(desktop-sm) {
      max-width: 1200px !important;
    }

    @include respond-min(desktop-lg) {
      max-width: 1440px !important;
    }

    > div {
      background: #FFFFFF0D;
      color: #FFFFFF99;
      padding: 5px 12px;
      border-radius: 6px;
      gap: 8px;
      width: 230px;
    }

    .footer__copyright {
      font-family: 'Montserrat', sans-serif;
      font-size: 11px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
  
      &:last-of-type {
        margin-bottom: 0;
      }
  
      &--its {
        a {
          color: #FFFFFF99 !important;
          text-underline-position: from-font;
          text-decoration-skip-ink: auto;
          text-decoration-line: underline;
          text-decoration-style: solid;
        }
      }
  
      @include respond-min(tablet) {
        margin-bottom: 0;
  
        @include respond-min(tablet) {
          &--its {
            text-transform: none;
          }
        }
      }
    }
  }
}

.notyf {
  align-items: center !important;
}
