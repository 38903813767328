.main-page {
  .our-products {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    grid-gap: 20px;
    justify-content: space-between;

    @include respond-min(tablet) {
      flex-direction: row;
    }
    h2 {
      width: 100%;
      margin-bottom: 15px;
    }
    &__card {
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      position: relative;
      margin-bottom: 10px;
      flex: 0 0 calc(33% - 11px);
      box-shadow: 0px 20px 44px 0px rgba(0, 0, 0, 0.3);
      overflow: hidden;

      @include respond-min(tablet) {

        &:hover {
          box-shadow: 0px 25px 40px -4px rgba(25, 35, 54, 0.12), 0px 10px 25px 0px rgba(25, 39, 52, 0.02);

          img {
            transform: scale(1.1);
            clip-path: inset(-100vw -100vw 0 -100vw);
          }
        }
      }
      &.activated-by-scroll {
        box-shadow: 0px 25px 40px -4px rgba(25, 35, 54, 0.12), 0px 10px 25px 0px rgba(25, 39, 52, 0.02);

        img {
          transform: scale(1.1);
          clip-path: inset(-100vw -100vw 0 -100vw);
        }
      }
      &:last-of-type {
        margin-bottom: 0;

        @include respond-min(tablet) {
          margin-bottom: 10px;
        }
      }
      &--category {
        position: absolute;
        left: 30px;
        top: 25px;
        padding: 5px 12px;
        color: $red;
        font-size: 12px;
        font-weight: 700;
        line-height: 140%;
        background: $white;
        border-radius: 8px;
      }
      &--image {
        width: 100%;
        overflow: hidden;
        display: flex;

        img {
          margin: 15px 10px 0 auto;
          max-height: 140px;

          @include respond-min(tablet) {
            margin-top: 0;
          }
        }
      }
      &--info {
        padding: 35px 35px 20px 35px;
        background: $white;
        border-radius: 0 0 15px 15px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        h4 {
          margin-bottom: 10px;
          font-size: 17px;
          font-weight: 700;
          line-height: 140%;

          @include respond-min(tablet) {
            margin-bottom: 15px;
            font-size: 20px;
          }
        }
        p {
          margin-bottom: 20px;
        }
        .link-to-chapter {
          margin-top: auto;
          z-index: 2;

          svg {
            background: $grey-9;
          }
          &:hover {

            svg {
              background: $red;
            }
          }
        }
      }
    }
  }
}
