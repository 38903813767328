.main-page {
  .feedback-block {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    grid-gap: 14px;

    @include respond-min(tablet) {
      flex-direction: row;
      grid-gap: 20px;
    }

    &__card {
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      position: relative;
      margin-bottom: 5px;
      flex: 0 0 100%;
      padding: 50px 30px;

      h3 {
        margin-bottom: 10px;

        @include respond-min(tablet) {
          margin-bottom: 20px;
          width: 50%;
        }
      }

      p {
        margin-bottom: 20px;

        @include respond-min(tablet) {
          width: 50%;
        }
      }

      .link-to-chapter {
        margin-top: auto;
        z-index: 2;
        width: 50%;
      }

      img {
        position: absolute;
        right: 0;
        bottom: 0;
        border-radius: 0 0 15px 0;
        width: 150px;

        @include respond-min(tablet) {
          right: 15px;
          width: auto;
        }
      }
    }

    .hidden {
      display: none;
    }

    .modal-feedback__form {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9;
      height: 100vh;
      width: 100vw;


      &--hide-area {
        background-color: rgba($color: #000000, $alpha: 0.75);
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
        height: 100vh;
        width: 100vw;
        cursor: pointer;
      }

      .form__side-feedback {
        display: block;
        margin: 25px auto;
        width: 768px;
        z-index: 2;
        max-height: calc(100% - 50px);
        overflow-y: auto;
        padding: 32px;
        border-radius: 16px;

        @include respond-max(tablet-sm) {
          max-height: 100%;
          margin: 0;
          width: 100%;
          height: 100%;
          border-radius: 0;
        }

        .modal__header {
          margin-bottom: 32px;
          position: relative;

          .modal__title {
            font-family: 'Montserrat', sans-serif;;
            font-size: 28px;
            font-weight: 600;
            line-height: 36.4px;
            text-align: left;
          }

          .modal__close.btn__circle {
            position: absolute;
            right: -10px;
            top: -25%;

            &::before, &::after {
              display: none;
            }

            .btn__close {
              height: 20px;
              width: 20%;
              top: 50%;
              left: 50%;
              right: 0;

              &::before, &::after {
                background-color: #0E0F1180;
                left: 0;

                &:hover {
                  background-color: #0E0F11BF;
                }
              }
            }
          }
        }

        .form__content {
          margin-bottom: 16px !important;

          .form__input, .form__textarea {
            padding: 0;
            box-shadow: none;
            border-radius: 8px;
            margin-bottom: 16px;

            .input__input:not([type="checkbox"]) {
              font-family: 'Montserrat', sans-serif;
              font-size: 14px;
              font-weight: 500;
              line-height: 21px;
              text-align: left;
              color: #0E0F11BF;
              border-radius: 8px;
              background: #F7F7F8;
              padding: 7px 16px 0;
              height: 48px;
            }

            textarea {
              padding: 21px 16px 0 !important;
              font-family: 'Montserrat', sans-serif;
              font-size: 14px;
              font-weight: 500;
              line-height: 21px;
              text-align: left;
              color: #0E0F11BF;
              border-radius: 8px;
              background: #F7F7F8;
              height: 48px;
              border: 0;
            }

            .input__label {
              font-family: 'Montserrat', sans-serif;
              font-size: 14px;
              font-weight: 500;
              line-height: 21px;
              text-align: left;
              color: #0E0F1180;
              top: 13px !important;
              background: transparent !important;
              left: 16px;
              color: #0E0F1180;

            }

            &.active, &.invalid {
              box-shadow: none;

              .input__label {
                top: 5px !important;
                font-size: 11px;
                font-weight: 500;
                line-height: 14.3px;
                letter-spacing: -0.02em;
                text-align: left;
                padding: 0;
              }
            }

            &.invalid {
              margin-bottom: 36px;

              .input__input:not([type="checkbox"]) {
                background: #FEECED;
              }
            }
          }

          .delete-files {
            padding-bottom: 10px;
            text-decoration: underline;
            font-size: 1.4rem;
            text-align: right;
            @include respond-min(tablet) {
              font-size: 0.85rem;
            }

            &:hover {
              text-decoration: none;
              cursor: pointer;
            }

            &[disabled="true"] {
              display: none;
            }
          }

          .files-list {
            & > div {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: .5rem;
              padding: 5px 5px 5px 10px;
              /*@include respond-min(tablet) {
                  font-size: 0.85rem;
              }*/
              &:hover {
                background: #edf1f2;
              }

              a {
                text-decoration: none;

                &:hover {
                  cursor: pointer;
                }
              }
            }

            &[disabled="true"] {
              & > div {
                &:hover {
                  background: transparent;
                }

                a {
                  display: none;
                }
              }
            }
          }

        }

        .form__file {
          border-radius: 8px !important;
          background: #F4F5F6;
          border: 2px dashed #0E0F1140 !important;
          margin-bottom: 12px;
          height: 48px;

          .file__upload {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            label {
              vertical-align: middle;

              .file__title {
                font-family: 'Montserrat', sans-serif;
                font-size: 13px;
                font-weight: 500;
                line-height: 18.2px;
                color: #E73760;

                .file__title-subtitle {
                  color: #0E0F1180;
                  opacity: 0.5;
                  font-size: 11px;
                  font-weight: 400;
                  line-height: 14.3px;
                }
              }
            }

            .file__del {
              display: none;
            }
          }
        }

        .form__checkbox {
          border-radius: 8px;
          background: #f4f5f6;
          padding: 10px 16px 10px 0;
          margin-bottom: 16px;

          &.invalid {
            background: #FEECED;
          }

          label {
            font-family: 'Montserrat', sans-serif;
            font-size: 11px;
            font-weight: 400;
            line-height: 15.4px;
            text-align: left;
            padding-left: 44px;

            a {
              color: #0E0F1180;
              border: 0;

              &:hover {
                border-bottom: 1px solid #0E0F11BF;
                color: #0E0F11BF;
              }
            }

            &.feedback_questionPersonalData_no_star {
              .clr-red {
                display: none;
              }
            }

            &::before {
              height: 16px !important;
              width: 16px !important;
              left: 16px !important;
              border-radius: 0;
              background: $white;
            }

            &::after {
              left: 16px !important;
              top: 0;
              height: 16px !important;
              width: 16px !important;
              border-radius: 0;
              background: url("/assets/src/assets/img/main-page/icons/checked.png") center no-repeat $red;

              @include respond-max(tablet) {
                top: 50%;
              }

            }
          }
        }

        .input {
          &::after {
            font-family: 'Montserrat', sans-serif;
            font-size: 11px;
            font-weight: 500;
            line-height: 15.4px;
            text-align: left;
            color: #DA111B;
            top: calc(100% + 4px);
            left: 16px;
          }
        }

        .file-info {
          color: #0E0F1180;
          font-family: 'Montserrat', sans-serif;
          font-size: 11px;
          font-weight: 400;
          line-height: 14.3px;
          text-align: left;
          margin-bottom: 16px;
          padding-left: 16px;
        }

        .form__content--title {
          text-align: center;
          margin-bottom: 1.875rem;
          font-weight: 600;
          font-size: 18px;
        }

        .form-label {
          margin-bottom: 5px;
          font-size: 17px;
        }

        & .input.form__file {
          display: inline-block;
          width: 100%;
          border-radius: 1.875rem;
          box-shadow: none;
          border: 2px dashed #d1d7db;
          line-height: 2.1rem;
          font-size: .860625rem;
          color: #75808f;
          text-align: center;

          @include respond-max(tablet) {
            line-height: 4rem;
          }

          &.invalid {
            border-color: $red;

            @include respond-max(tablet) {
              margin-bottom: 5rem;
            }

            & + .file-info {
              margin-top: -0.313rem;
            }
          }

          & label, & .file__del {

            @include respond-max(tablet) {
              font-size: 1.625rem;
            }

            & input.input__file {
              display: none;
            }
          }

          & label:hover {
            color: #525454;
          }

          & .file-name {
            color: #75808f;
            font-size: .8rem;

            @include respond-max(tablet) {
              font-size: 1.625rem;
            }
          }

          & .file-error {
            color: #ad033b;
            font-size: .85rem;

            @include respond-max(tablet) {
              font-size: 1.4rem;
            }
          }

          & #progressBar {
            display: none;
            width: 100%;
            max-width: 305px;
            margin-top: 1rem;
          }

          .file__title:hover,
          .file__del:hover {
            cursor: pointer;
          }

          .file__list {
            padding: 0 2rem;
          }

          .file__item {
            display: flex;
            justify-content: space-between;
            margin: 0;
            padding: 0;
            font-size: 1rem !important;
            color: #777;
          }
        }

        .modal__footer {
          justify-content: flex-start;

          .send_feedback {
            width: 50%;
            border-radius: 8px;
            background: $red;
            padding: 17px 106px;

            .btn__text {
              font-family: 'Montserrat', sans-serif;
              font-size: 11px;
              font-weight: 600;
              line-height: 14.3px;
              letter-spacing: 0.02em;

            }

            &:hover {
              background: #D51148;
            }

            @include respond-max(tablet-sm) {
              margin-bottom: 50px;
            }

          }
        }
      }

      .form__success-content-wrapper {
        width: 100%;
        //padding: 32px;
        border-radius: 16px;

        @include respond-max(tablet-sm) {
          width: 100%;
        }

        .form-success__main {
          margin: 0;
          padding: 16px;
          border-radius: 8px;
          background: #F5FFF7;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          gap: 24px;

          .form-success__text {
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;

            .form-success__title {
              margin: 0;
              font-family: 'Montserrat', sans-serif;
              font-size: 13px;
              font-weight: 500;
              line-height: 18.2px;
              text-align: left;
              color: #00BF2A;
            }
          }

          .form__success--close {
            padding: 4px;
            height: 24px;
            width: 24px;
            border-radius: 4px;
            border: 0;
            background: #0E0F110D;

            svg {
              height: 100%;
              width: 100%;
              fill: #0E0F1180;

              &:hover {
                fill: #0E0F11BF;
              }
            }
          }
        }

        &.active {
          //margin: 40vh auto;
        }

        /*.form__success--close {
            position: absolute;
            top: 25px;
            right: 25px;
            color: $white;
            font-size: 18px;
            font-weight: 600;
            cursor: pointer;
        }
        .form__success-text {
            font-size: 20px;
        }*/
      }
    }
  }
  input[name="n-message"] {
    display: block;
    width: 5px;
    height: 3px;
    margin-bottom: -3px;
    opacity: 0.01;
  }
}
