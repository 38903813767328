@font-face {
	font-family: 'Montserrat';
	src: url('/assets/dist/fonts/montserrat/Montserrat-Light.eot');
	src: local('Montserrat Light'), local('Montserrat-Light'),
		url('/assets/dist/fonts/montserrat/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
		url('/assets/dist/fonts/montserrat/Montserrat-Light.woff') format('woff'),
		url('/assets/dist/fonts/montserrat/Montserrat-Light.woff2') format('woff2'),
		url('/assets/dist/fonts/montserrat/Montserrat-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/dist/fonts/montserrat/Montserrat-Thin.eot');
	src: local('Montserrat Thin'), local('Montserrat-Thin'),
		url('/assets/dist/fonts/montserrat/Montserrat-Thin.eot?#iefix') format('embedded-opentype'),
		url('/assets/dist/fonts/montserrat/Montserrat-Thin.woff') format('woff'),
		url('/assets/dist/fonts/montserrat/Montserrat-Thin.woff2') format('woff2'),
		url('/assets/dist/fonts/montserrat/Montserrat-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/dist/fonts/montserrat/Montserrat-SemiBold.eot');
	src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
		url('/assets/dist/fonts/montserrat/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('/assets/dist/fonts/montserrat/Montserrat-SemiBold.woff') format('woff'),
		url('/assets/dist/fonts/montserrat/Montserrat-SemiBold.woff2') format('woff2'),
		url('/assets/dist/fonts/montserrat/Montserrat-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/dist/fonts/montserrat/Montserrat-ExtraLight.eot');
	src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
		url('/assets/dist/fonts/montserrat/Montserrat-ExtraLight.eot?#iefix') format('embedded-opentype'),
		url('/assets/dist/fonts/montserrat/Montserrat-ExtraLight.woff') format('woff'),
		url('/assets/dist/fonts/montserrat/Montserrat-ExtraLight.woff2') format('woff2'),
		url('/assets/dist/fonts/montserrat/Montserrat-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/dist/fonts/montserrat/Montserrat-Bold.eot');
	src: local('Montserrat Bold'), local('Montserrat-Bold'),
		url('/assets/dist/fonts/montserrat/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
		url('/assets/dist/fonts/montserrat/Montserrat-Bold.woff') format('woff'),
		url('/assets/dist/fonts/montserrat/Montserrat-Bold.woff2') format('woff2'),
		url('/assets/dist/fonts/montserrat/Montserrat-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/dist/fonts/montserrat/Montserrat-Regular.eot');
	src: local('Montserrat Regular'), local('Montserrat-Regular'),
		url('/assets/dist/fonts/montserrat/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
		url('/assets/dist/fonts/montserrat/Montserrat-Regular.woff') format('woff'),
		url('/assets/dist/fonts/montserrat/Montserrat-Regular.woff2') format('woff2'),
		url('/assets/dist/fonts/montserrat/Montserrat-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/dist/fonts/montserrat/Montserrat-Medium.eot');
	src: local('Montserrat Medium'), local('Montserrat-Medium'),
		url('/assets/dist/fonts/montserrat/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
		url('/assets/dist/fonts/montserrat/Montserrat-Medium.woff') format('woff'),
		url('/assets/dist/fonts/montserrat/Montserrat-Medium.woff2') format('woff2'),
		url('/assets/dist/fonts/montserrat/Montserrat-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/dist/fonts/montserrat/Montserrat-ExtraBold.eot');
	src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
		url('/assets/dist/fonts/montserrat/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
		url('/assets/dist/fonts/montserrat/Montserrat-ExtraBold.woff') format('woff'),
		url('/assets/dist/fonts/montserrat/Montserrat-ExtraBold.woff2') format('woff2'),
		url('/assets/dist/fonts/montserrat/Montserrat-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/dist/fonts/montserrat/Montserrat-Black.eot');
	src: local('Montserrat Black'), local('Montserrat-Black'),
		url('/assets/dist/fonts/montserrat/Montserrat-Black.eot?#iefix') format('embedded-opentype'),
		url('/assets/dist/fonts/montserrat/Montserrat-Black.woff') format('woff'),
		url('/assets/dist/fonts/montserrat/Montserrat-Black.woff2') format('woff2'),
		url('/assets/dist/fonts/montserrat/Montserrat-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}
