.main-page {
  .investment-services {

    h2 {
      margin-bottom: 40px;
    }

    &__block {
      width: 100%;
      display: flex;

      @include respond-min(tablet) {
        border-radius: 15px;
        background: $white;
        box-shadow: 0px 20px 80px -10px rgba(0, 0, 0, 0.10);
        padding: 40px;
      }

      &--info {
        flex: 0 0 100%;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
        position: relative;

        @include respond-min(tablet) {
          flex: 0 0 65%;
        }

        .accordion {
          width: 100%;
          display: flex;
          flex-direction: column;
          grid-gap: 12px;
          scroll-margin-top: 100px;

          &__item {
            border-radius: 15px;
            background: $grey-9;
            padding: 27px;
            position: relative;
            overflow: hidden;

            &.active {

              &::before {
                position: absolute;
                content: '';
                width: 65%;
                height: 3px;
                display: block;
                left: 0px;
                top: 0;
                background: $red;

                @include respond-min(tablet) {
                  height: 2px;
                  width: 20%;
                }
              }

              //чтобы в мобилке при открытии картинка поднималась
              img {
                transform: translate(-50%, 20px);
              }
            }

            /*.shadow {
                display: none;
                position:absolute;
                width:2px;
                height:2px;
            }
            &:hover .shadow {
                display: block;
                box-shadow: 2px 2px 28px 5px #000;
            }*/
          }

          &__title {
            margin: 0 0 10px 0;
            padding: 0;
          }

          &__content {

            .link-to-chapter {
              margin-top: 40px;
            }
          }
        }
      }

      &--image {
        display: none;
        align-items: center;
        flex: 0 0 35%;
        justify-content: center;
        margin-top: 100px;

        @include respond-min(tablet) {
          display: flex;
          margin-top: 40px;
        }

        div {
          position: relative;
          height: 290px;
          width: 290px;
          border-radius: 50%;
          background: $grey-13;
          clip-path: inset(-100vw 0 15px 0 round 50%);

          @include respond-min(tablet-md) {
            height: 310px;
            width: 310px;
          }

          img {
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 75%;
            transform: translate(-50%, 40px);
          }

          &:hover, &.active {

            img {
              transform: translate(-50%, 0);
            }
          }
        }
      }

      //если на картинку навесить событие скриптом
      .activated-by-scroll {
        img {
          transform: translate(-50%, 0) !important;
        }
      }
    }
  }
}
