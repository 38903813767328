.main-page {
  .achievements-block {
    margin-bottom: 0;

    &__slider {
      padding: 35px 0 60px 0; //здесь уделить внимание, если слайдер не должен уходить за wrapper
      padding-left: $offset-mobile;
      padding-right: $offset-mobile;

      @include respond-min(tablet) {
        //padding: 40px 0 100px 0;
        //padding-left: $offset-desktop;
        //padding-right: $offset-desktop;
      }
      @include respond-min(desktop) {
        //padding-left: calc(50vw - #{$desktop} / 2 + #{$offset-desktop});
        //padding-right: calc(50vw - #{$desktop} / 2 + #{$offset-desktop});
      }

      .slider-dots {
        justify-content: center;
        grid-gap: 8px;

        &__dot {
          border-radius: 10px;
          background: #DFE8F1;
          height: 4px;
          width: 30px;
          margin: 30px 0 0 0;
          padding: 0;
        }

        &__dot.active {
          background: $red;
          width: 60px;

          @include respond-min(tablet) {
            width: 30px;
          }
        }
      }
    }

    &__slider-wrapper {

      @include respond-min(tablet) {
        flex-wrap: nowrap;
      }
    }

    &__card {
      background: $white;
      padding: 20px;
      border-radius: 15px;
      box-shadow: 0px 20px 44px 0px rgba($color: $black, $alpha: 0.05);

      @include respond-min(tablet) {
        padding: 30px;

        &:hover {
          box-shadow: 0px 20px 44px 0px rgba(0, 0, 0, 0.3);
          transform: translateY(-5px);
        }
      }

      &.activated-by-scroll {
        box-shadow: 0px 20px 44px 0px rgba(0, 0, 0, 0.3);
        transform: translateY(-5px);
      }

      picture {

        img {
          height: 51px;
          width: 38px;
          object-fit: cover;
        }
      }

      p {
        margin: 10px 0 15px 0;
        font-size: 17px;
        font-weight: 700;
        line-height: 140%;

        @include respond-min(tablet) {
          font-size: 20px;
        }
      }

      & > span {
        margin-bottom: 45px;
        font-size: 13px;
        font-weight: 400;
        line-height: 160%;

        @include respond-min(tablet) {
          font-size: 15px;
        }
      }

      .link-to-chapter {
        margin-top: auto;
      }
    }
  }
}
