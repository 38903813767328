.main-page {
  .materials-block {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    grid-gap: 14px;

    @include respond-min(tablet) {
      flex-direction: row;
      grid-gap: 20px;
    }

    h2 {
      width: 100%;
      margin-bottom: 20px;

      @include respond-min(tablet) {
        margin-bottom: 35px;
      }
    }

    &__card {
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      position: relative;
      margin-bottom: 5px;
      flex: 0 0 calc(50% - 10px);
      padding: 35px 35px 20px 35px;

      @include respond-min(tablet) {

        &:hover:not(.locked-card) {
          transform: translateY(-5px);
        }
      }

      &.activated-by-scroll:not(.locked-card) {
        transform: translateY(-5px);
      }

      &:last-of-type {
        margin-bottom: 0;

        @include respond-min(tablet) {
          margin-bottom: 5px;
        }
      }

      &.locked-card {

        .locked-card-icon {
          display: block;
          position: absolute;
          width: 125px;
          height: 125px;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          background: url("../../../src/assets/img/main-page/material-locked.png") rgba(163, 170, 190, 0.30);
          backdrop-filter: blur(3.5px);
          background-repeat: no-repeat;
          background-position: center;
          z-index: 1;
        }
      }

      h3 {
        margin-bottom: 10px;

        @include respond-min(tablet) {
          margin-bottom: 20px;
        }
      }

      p {
        margin-bottom: 70px;
      }

      .link-to-chapter {
        margin-top: auto;
        z-index: 2;
      }

      img {
        position: absolute;
        right: 0;
        bottom: 0;
        border-radius: 0 0 15px 0;
      }

      &--social {
        margin-top: auto;
        display: flex;
        grid-gap: 14px;
        z-index: 2;

        a {
          border-radius: 50%;
          display: flex;

          * {
            transition-duration: 0.7s;
          }

          &:hover {
            circle, rect {
              fill: none;
            }

            path {
              fill: $black;
            }
          }

          &.no-offset-logo {
            &:hover {
              background: $black;

              path {
                fill: $light-pink;
              }
            }
          }
        }
      }
    }
  }
}
