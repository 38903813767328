.main-page {
  overflow-x: hidden;

  .bg-grayblue {
    background: $grey-9;
  }
  .bg-light-pink {
    background: $light-pink;
  }
  .accordion__content_list {
    list-style: disc;
    padding-left: 20px;
  }

  .error-template {
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 500;
    line-height: 15.4px;
    text-align: left;
    padding: 8px 16px;

    &.active {
      display: block;
    }
  }
  * {
    &[disabled="true"] {
      cursor: not-allowed;
    }
  }
}
@keyframes horizontal_move {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-30px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(7deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes pagination_line {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
