.main-page {
  .our-team-block {

    h2 {
      margin-bottom: 35px;

      @include respond-min(tablet) {
        margin-bottom: 40px;
      }
    }

    &__image {
      display: flex;
      flex-direction: column;
      border-radius: 15px;
      text-decoration: none;
      position: relative;

      &--wrapper {
        overflow-x: auto;
        border-radius: 15px 15px 0 0;

        img {
          object-fit: contain;
          max-height: 480px;
          display: flex;

          @include respond-min(tablet) {
            width: 100%;
          }
        }

        .mobile-tooltip {
          position: absolute;
          margin-top: -70px;
          left: 50%;
          transform: translateX(-50%);
          display: block;
          pointer-events: none;

          @include respond-min(tablet) {
            display: none;
          }

          &.hidden {
            opacity: 0;
            transform: translateX(-50%) scale(0.5);
          }
        }
      }

      &--info {
        color: $white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: 40px;
        background: $black-color1;
        border-radius: 0 0 15px 15px;

        @include respond-min(tablet) {
          flex-direction: row;
          justify-content: space-between;
          text-align: start;
          padding: 35px 60px;
        }

        h3 {
          font-size: 22px;
          font-weight: 700;
          line-height: 140%;
          margin-bottom: 25px;

          @include respond-min(tablet) {
            font-size: 28px;
            margin-bottom: 0;
            max-width: 35%;
          }
        }

        p {
          font-size: 15px;
          font-weight: 500;
          line-height: 160%;

          @include respond-min(tablet) {
            font-size: 18px;
            margin-right: 40px;
            max-width: 50%;
          }
        }
      }
    }
  }
}
