.main-page {
  .mobileapp-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: $grey-9;
    border-radius: 15px;
    overflow-x: hidden;
    overflow-y: hidden;

    @include respond-min(tablet) {
      flex-direction: row;
    }

    h2, h3 {
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 25px;

      @include respond-min(tablet) {
        max-width: 90%;
      }
      @include respond-min(desktop-sm) {
        max-width: 100%;
      }
    }

    &__info {
      padding: 20px 30px;

      @include respond-min(tablet) {
        padding: 45px 50px;
      }

      &--socials {
        display: flex;
        grid-gap: 14px;
        margin-bottom: 25px;

        * {
          transition-duration: 0.7s;
        }

        a:hover {
          circle, rect {
            fill: none;
          }

          path {
            fill: $black;
          }
        }
      }
    }

    &__image {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      width: 100%;
      min-height: 170px;
      margin: 0 auto;
      position: relative;

      @include respond-min(mobile-mx) {
        justify-content: center;
        width: 60%;
        margin: 0 auto;
      }
      @include respond-min(tablet) {
        justify-content: flex-end;
        margin: 0;
        width: 40% !important;
      }

      &.activated-by-scroll {

        .mobileapp-image-svg {
          animation-name: horizontal_move;
          animation-duration: 1s;
          animation-iteration-count: 1;
          animation-timing-function: linear;
          animation-fill-mode: forwards;

          &.mobileapp-image-1 {
            animation-delay: 0.3s;
          }

          &.mobileapp-image-2 {
            animation-delay: 0.6s;
          }

          &.mobileapp-image-3 {
            animation-delay: 0.9s;
          }

          &.mobileapp-image-4 {
            animation-delay: 1.2s;
          }
        }
      }

      img {
        object-fit: contain;
        width: 100%;

        &.mobileapp-image-svg {
          position: absolute;
          bottom: 0;
          right: -10px;
          width: auto;
          max-height: 100%;
          width: 250px;

          @include respond-min(mobile-md) {
            width: 320px;
            right: -60px;
          }
          @include respond-min(tablet-sm) {
            width: 400px;
          }

          &.mobileapp-image-2 {
            right: 50px;
            bottom: 20px;
            width: 160px;

            @include respond-min(mobile-md) {
              width: 180px;
            }
            @include respond-min(mobile-ml) {
              right: 50px;
            }
            @include respond-min(tablet-sm) {
              right: 105px;
              width: 195px;
            }
          }

          &.mobileapp-image-3 {
            right: 80px;
            width: 155px;

            @include respond-min(mobile-md) {
              width: 175px;
            }
            @include respond-min(mobile-ml) {
              right: 100px;
            }
            @include respond-min(tablet-sm) {
              right: 175px;
              width: 195px;
            }
          }

          &.mobileapp-image-4 {
            right: 110px;
            bottom: 35px;
            width: 150px;

            @include respond-min(mobile-md) {
              width: 175px;
            }
            @include respond-min(mobile-ml) {
              right: 150px;
            }
            @include respond-min(tablet-sm) {
              right: 230px;
              width: 210px;
            }
          }
        }
      }
    }
  }
}
