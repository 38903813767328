
.main-page {
  .wrapper-m {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;

    @include respond-min(tablet-sm) {
      max-width: 696px;
    }

    @include respond-min(tablet-sl) {
      max-width: 912px;
      padding-left: 0;
      padding-right: 0;
    }

    @include respond-min(desktop-sm) {
      max-width: 1200px !important;
    }

    @include respond-min(desktop-lg) {
      max-width: 1440px !important;
    }
  }

  .main-slider {
    position: relative;
    padding: 0;
    display: flex;
    flex-direction: column;
    background-color: #007b86;
    background-image: radial-gradient(circle closest-corner at 38% 120%, #024e47, rgba(0, 128, 119, 0)), radial-gradient(circle closest-corner at 50% -30%, #8df9ff, rgba(148, 255, 225, .32) 41%, rgba(228, 255, 247, 0) 77%), linear-gradient(264deg, #003031, rgba(40, 158, 154, 0) 53%);

    @include respond-min(tablet-sl) {
      margin-bottom: 190px !important;
      display: block;
      height: 630px;
    }

    video {
      width: 100%;
      position: absolute;
      height: 100%;
      object-fit: cover;
      opacity: .7;
      mix-blend-mode: multiply;
    }

    .slider-top {
      height: 100%;

      @include respond-min(tablet-sm) {
        max-width: 696px;
      }

      @include respond-min(tablet-sl) {
        max-width: 912px;
      }

      @include respond-min(desktop-sm) {
        max-width: 1200px !important;
      }

      @include respond-min(desktop-lg) {
        max-width: 1440px !important;
      }
    }

    .slider-top__slide {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column-reverse;
      background: transparent;
      padding-left: 16px;
      padding-right: 16px;

      @include respond-min(tablet-sm) {
        flex-direction: row;
        /*padding-left: 36px;
        padding-right: 36px;*/
        max-height: 600px;
        max-width: 696px;
      }

      @include respond-min(tablet-sl) {
        padding-top: 0px;
        padding-bottom: 0px;
        /*padding-left: 26px;
        padding-right: 26px;*/
        max-width: 912px;
        max-height: none;
      }

      @include respond-min(desktop-sm) {
        /*padding-left: 0 !important;
        padding-right: 0 !important;*/
        max-width: 1200px !important;
      }

      @include respond-min(desktop-lg) {
        max-width: 1440px !important;
      }

      &::before, &::after {
        display: none;
      }

      &.slider-top__slide_about {
        @include respond-max(tablet) {
          position: relative;
        }

        .slider-top__block {
          color: $white;
          position: relative;

          &:last-of-type {
            height: 100%;
          }
        }
      }

      &.slider-top__slide_services {
        .slider-top__block {
          

          &:last-of-type {
            height: 100%;
          }
        }
      }

      .slider-top__block {
        font-family: 'Montserrat', sans-serif;
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
        color: $white;
        flex: 0 0 50%;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        letter-spacing: -0.005em;
        
        @include respond-min(desktop-sm) {
          font-size: 17px;
          line-height: 24px;
        }
        
        @include respond-min(desktop-lg) {
          font-size: 19px !important;
          line-height: 27px !important;
        }

        &:first-child {
          margin-bottom: 50px;

          @include respond-min(tablet) {
            margin-bottom: 0;
          }
        }

        .slider-top__title {
          font-family: 'Montserrat', sans-serif;
          font-size: 28px;
          font-weight: 600;
          line-height: 34px;
          margin-bottom: 20px;
          color: $white;
          letter-spacing: -0.005em;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;

          @include respond-min(tablet-sm) {
            font-size: 33px;
            line-height: 39px;
          }

          @include respond-min(tablet-sl) {
            font-size: 40px;
            line-height: 46px;
          }

          @include respond-min(desktop-sm) {
            font-size: 52px !important;
            font-weight: 500;
            line-height: 56px !important;
          }

          @include respond-min(desktop-lg) {
            font-size: 64px !important;
            line-height: 68px !important;
          }
        }

        a {
          margin-top: 24px;
          width: 100%;
          font-family: 'Montserrat', sans-serif;
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          letter-spacing: -0.005em;
          
          @include respond-min(tablet-sm) {
            width: fit-content;
            font-size: 15px;
            line-height: 20px;
          }
          
          @include respond-min(desktop-sm) {
            margin-top: 40px;
            font-weight: 600;
          }

          &::after {
            content:  url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAB+SURBVHgB7dSxDYAgFATQwwkYwREYxQ1kE9lANnIUR9AN8Ii/oLGRq4wv+Z25CBwAv1YpZYIKwzxn56xQYVjgHJwFKgyL5TZDhWHJ/jRAhWHZ9nSECsM2C/VP3wx4wTl3oletj2zJtTayQ6l1sdpE9GqKndCruXoZKtLH4bsubNl769CZ7d0AAAAASUVORK5CYII=');
            display: block;
            width: 20px;
            height: 20px;
          }
        }

        .slider_picture_about {
          position: relative;
          background: url("../../../src/assets/img/main-page/slider-2_bg.png") 50% 50%;
          background-position: 50%;
          background-repeat: no-repeat;
          background-size: contain;
          max-height: unset !important;

          @include respond-min(tablet) {
            background-size: 100%;
          }

          picture {
            position: relative;
          }
        }

        .slider_picture_future {
          position: relative;
          background: url("../../../src/assets/img/main-page/slider-1_bg.png") 50% 50%;
          background-position: 50%;
          background-position-y: bottom;
          background-repeat: no-repeat;
          background-size: contain;
          
          @include respond-min(tablet) {
            background-size: 100%;
          }
          picture {
            position: relative;
          }
        }

        .slider_picture_0 {
          position: relative;
          background: url("../../../src/assets/img/main-page/slider-0m_bg.png") 50% 50%;
          background-position: left top;
          background-repeat: no-repeat;
          background-size: cover;
          
          @include respond-min(tablet) {
            background-position: 80% !important;
            background: url("../../../src/assets/img/main-page/slider-0_bg.png") 50% 50%;
          }
          picture {
            position: relative;
          }
        }

        .slider_picture {
          max-height: 50vh;

          @include respond-min(tablet) {
            max-height: none;
          }

          picture {
            align-items: center;
            justify-content: center;
          }

          img {
            width: auto;
            height: auto;
            animation-name: move;
            animation-duration: 25s;
            animation-iteration-count: infinite;
            
            @include respond-min(tablet) {
              margin-bottom: 56px;
            }
            
            @keyframes move {
              0%, 100% {
                transform: translateY(0) scale(1);
              }
              40% {
                transform: translateY(-12px) scale(1);
              }
              80% {
                transform: translateY(6px) scale(0.9);
              }
            }
          }
        }
      }
    }

    &__cards {
      //overflow-x: hidden;
      z-index: 2;
      background: $white;

      @include respond-min(tablet-sm) {
        max-width: 696px;
      }

      @include respond-min(tablet-sl) {
        position: relative;
        margin-top: 0;
        max-width: 912px;
      }

      @include respond-min(desktop-sm) {
        margin-left: auto;
        margin-right: auto;
        max-width: 1200px !important;
      }

      @include respond-min(desktop-lg) {
        max-width: 1440px !important;
      }

      .wrapper {
        display: flex;
        justify-content: space-between;
        grid-gap: 10px;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 0;
        margin-left: -$offset-mobile;
        text-align: center;
        margin-top: -120px;

        @include respond-min(tablet-sm) {
          padding-left: 36px;
          padding-right: 36px;
        }

        @include respond-min(tablet-sl) {
          /*padding-left: $offset-desktop;
          padding-right: $offset-desktop;*/
          padding-left: 40px;
          padding-right: 40px;
          margin-left: auto;
          margin-right: auto;
          /*padding-left: 0 !important;
          padding-right: 0 !important;*/
          margin-top: 0;
          //padding-bottom: 0;
          grid-gap: 20px;
          position: absolute;
          left: 0;
          z-index: 2;
          bottom: -90px;
          overflow-x: visible;
          width: 100%;
          height: auto;
          margin-left: 0;
          box-sizing: border-box;
          //max-width: 1200px;
        }

        @include respond-min(desktop-sm) {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }

        /*@include respond-min(desktop-sm) {
          padding-left: 40px !important;
          padding-right: 40px !important;
        }*/

        div {
          font-family: 'Montserrat', sans-serif;
          background: $white;
          border-radius: 15px;
          padding: 20px 10px;
          font-weight: 600;
          font-size: 13px;
          line-height: 150%;
          color: rgba($color: $black-color1, $alpha: 0.8);
          flex: 0 0 48%;
          box-shadow: 0px 12px 30px -10px rgba(0, 0, 0, 0.2);
          box-sizing: border-box;
          height: auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          
          @include respond-min(tablet-sl) {
            font-size: 15px;
            flex: 0 0 calc(25% - 20px);
            padding: 25px 25px;
            box-shadow: 0px 25px 40px -10px rgba(61, 68, 94, 0.2);
            border-bottom: 2px solid $white;
            transition-duration: 0.5s;
            &:hover {
              border-bottom: 2px solid $red;
              background: $white;
              //padding: 35px 25px 33px 25px;
            }
          }
          @include respond-min(desktop) {
            padding: 35px 25px;
          }

          p {
            font-weight: 700;
            font-size: 15px;
            line-height: 120%;
            color: $red;
            margin-bottom: 12px;

            @include respond-min(tablet-sl) {
              font-size: 15px;
            }
            @include respond-min(desktop-sm) {
              font-size: 18px;
            }
          }
        }
      }
    }

    .main-slider-dots {
      position: absolute;
      bottom: unset;
      //width: 100%;
      left: 0;
      display: flex;
      justify-content: center;
      z-index: 2;
      grid-gap: 6px;
      max-height: 50px;
      background: #0E0F1140;
      padding: 8px 16px;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      top: 0;

      @include respond-min(tablet-sm) {
        grid-gap: 8px;
        top: unset;
        bottom: 140px;
        width: fit-content;
        border-radius: 360px;
      }

      @include respond-min(tablet) {
        bottom: 110px !important;
      }

      &__dot {
        height: 3px;
        width: 40px;
        border-radius: 10px;
        background: rgba($color: $white, $alpha: 0.4);
        border: 0;


        @include respond-min(tablet) {
          height: 4px;
          width: 28px;
        }

        &.active {
          position: relative;
          width: 135px;

          &::after {
            position: absolute;
            content: '';
            background: $red;
            top: 0;
            left: 0;
            height: 100%;
            width: 0;
            animation-delay: 0.5s;
            animation-duration: 4s;
            animation-name: pagination_line;
            animation-iteration-count: 1;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
            animation-play-state: running;
          }

          @include respond-min(tablet) {
            width: 85px;
          }
        }
      }
    }

    &--animated-phone {
      display: flex;
      justify-content: center;
      margin-top: 100px;

      @include respond-min(tablet) {
        margin-top: 0;
      }

      div {
        position: relative;
        height: 100vw;
        width: 100vw;

        max-height: 260px;
        max-width: 260px;
        border-radius: 50%;
        background: $gradient-darkblue-gray;
        clip-path: inset(-100vw 0 15px 0 round 50%);

        @include respond-min(mobile-ml) {
          max-height: 260px;
          max-width: 260px;
        }
        @include respond-min(tablet) {
          max-height: 360px !important;
          max-width: 360px !important;

          &:hover {
            img {
              transform: translate(-50%, 80px);
            }
          }
        }
        @include respond-min(desktop) {

          &:hover {
            img {
              transform: translate(-50%, 0);
            }
          }
        }

        img {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 40px);
          width: 75%;

          @include respond-min(tablet) {
            transform: translate(-50%, 100px);
          }
          @include respond-min(desktop) {
            transform: translate(-50%, 40px);
          }
        }

        &.activated-by-scroll {
          img {
            transform: translate(-50%, 0);

            @include respond-min(tablet) {
              transform: translate(-50%, 80px);
            }
            @include respond-min(desktop) {
              transform: translate(-50%, 0);
            }
          }
        }

        &.horizontal {
          clip-path: inset(0 0 0 -100vw round 50%);

          img {
            position: absolute;
            bottom: 50%;
            left: -20%;
            transform: translate(40px, 50%);
            width: 115%;

            @include respond-min(mobile-ml) {
              width: 125%;
            }
          }

          &:hover {
            img {
              transform: translate(0, 50%);
            }
          }
        }
      }
    }

    .paused {

      &::after {
        animation: none !important;
      }
    }
  }
}
