.main-page {
  .actuality-now {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    grid-gap: 14px;

    @include respond-min(tablet) {
      flex-direction: row;
      grid-gap: 20px;
    }

    h2 {
      width: 100%;
      margin-bottom: 20px;

      @include respond-min(tablet) {
        margin-bottom: 32px;
      }
    }

    &__card {
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      position: relative;
      margin-bottom: 5px;
      flex: 0 0 calc(33% - 10px);
      padding: 24px;

      &-full-width {
        padding: 0 19px 20px;
        flex-basis: 100%;
        justify-content: space-between;
        display: flex;
        flex-direction: column-reverse;

        @include respond-min(tablet) {
          flex-direction: row;
          padding-top: 0;
          padding-bottom: 0;
          padding-right: 0;
        }

        img, picture {
          max-width: 485px;
          height: auto;

          @include respond-max(tablet) {
            width: 100%;
          }
        }

        h3 {
          font-size: 18px;
          margin-bottom: 20px;
          line-height: 22.5px;

          @include respond-max(tablet) {
            margin-top: 16px;
          }
        }

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          margin-bottom: 40px;
        }

        .red-button {
          padding: 19px 32px;
          font-weight: 500;
          line-height: 16.8px;
          letter-spacing: -0.005em;

          &::after {
            content:  url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAB+SURBVHgB7dSxDYAgFATQwwkYwREYxQ1kE9lANnIUR9AN8Ii/oLGRq4wv+Z25CBwAv1YpZYIKwzxn56xQYVjgHJwFKgyL5TZDhWHJ/jRAhWHZ9nSECsM2C/VP3wx4wTl3oletj2zJtTayQ6l1sdpE9GqKndCruXoZKtLH4bsubNl769CZ7d0AAAAASUVORK5CYII=');
            display: block;
            width: 20px;
            height: 20px;
          }
        }
      }

      &-left-block {
        padding-top: 24px;
        @include respond-min(tablet) {
          padding-bottom: 24px;
        }
      }

      &-right-block {
        text-align: right;
      }

      @include respond-min(tablet) {

        &:hover {
          transform: translateY(-5px);
        }
      }

      &.activated-by-scroll {
        transform: translateY(-5px);
      }

      &:last-of-type {
        margin-bottom: 0;

        @include respond-min(tablet) {
          margin-bottom: 5px;
        }
      }

      h3 {
        margin-bottom: 12px;
        font-size: 16px;
        font-weight: 600;
        line-height: 20.8px;

        @include respond-min(tablet) {
          //margin-bottom: 20px;
        }
      }

      p {
        margin-bottom: 31px;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
      }

      .link-to-chapter {
        margin-top: auto;
        z-index: 2;
      }

    }

    .all-news {
      width: 100%;
      padding-left: 24px;

      @include respond-min(tablet) {
        justify-content: center;
        padding-left: 0;
      }

      svg {
          background: #EEEFF1;
      }

      &:hover {

        svg {
          background: $red;

          use {
            filter: invert(100%);
          }
        }
      }

    }

  }
}
