.main-page {
  .analytic-promo {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;

    &__title {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      grid-gap: 25px;

      @include respond-min(tablet) {
        margin-bottom: 20px;
      }

      &--locked {
        position: relative;
        cursor: pointer;

        img {
          width: 54px;
          height: 54px;

        }

        .tooltip {
          opacity: 0;
          pointer-events: none;
          display: none;
          position: absolute;
          top: -60px;
          left: 0;
          background: $white;
          border-radius: 8px;
          border: 0;
          box-shadow: 0px 10px 30px -6px rgba(41, 45, 50, 0.08);
          padding: 10px 20px;
          font-size: 11px;
          font-weight: 500;
          line-height: 130%;
          color: rgba($color: $black-color1, $alpha: 0.85);
          white-space: nowrap;

          @include respond-min(tablet) {
            display: inline-table;
          }
        }

        &:hover .tooltip {
          pointer-events: all;
          opacity: 1;
        }
      }
    }

    &__access-advantages {
      display: flex;
      flex-direction: column;
      grid-gap: 15px;

      @include respond-min(tablet) {
        flex-direction: row;
        grid-gap: 20px;
      }

      &--card {
        flex: 0 0 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: $grey-9;
        border-radius: 15px;
        padding: 20px;

        @include respond-min(tablet) {
          flex: 0 0 calc(25% - 15px);
          padding: 25px;
        }

        img {
          width: 40px;
          height: 40px;

        }

        p {
          margin-top: 15px;
          font-size: 15px;
          font-weight: 700;
          line-height: 140%;

          @include respond-min(tablet) {
            font-size: 18px;
          }
        }
      }
    }

    &__form {
      background: $grey-9;
      position: relative;
      overflow: hidden;
      border-radius: 15px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column-reverse;

      @include respond-min(tablet) {
        flex-direction: row;
      }

      form {
        padding: 0;
        padding: 0 20px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        width: 100%;

        @include respond-min(tablet) {
          padding: 30px 0 0 40px;
        }
        @include respond-min(desktop) {
          padding: 30px 40px;
        }

        p {
          font-size: 22px;
          font-weight: 700;
          line-height: 140%;
          width: 100%;
          margin-bottom: 25px;
        }

        .form__input {
          flex: 0 0 100%;
          font-size: 15px;
          font-weight: 400;
          line-height: 160%;
          color: $black-color1;
          border-radius: 10px;
          background: $white;

          @include respond-min(tablet) {
            flex: 0 0 calc(34% - 10px);
            margin-right: 10px;
          }
          @include respond-min(desktop) {
            flex: 0 0 calc(36% - 10px);
          }

          .input__input {
            padding: 14px 22px;

          }

          .input__label {
            display: none;
          }

          &::after {
            left: 1rem;
          }
        }

        button {
          flex: 0 0 100%;
          color: $white;
          border: 0;
          padding: 14px 28px;
          border-radius: 10px;
          box-shadow: 0px 12px 25px 0px rgba(167, 0, 40, 0.25);
          background: $red;
          display: flex;
          grid-gap: 5px;
          justify-content: center;
          align-items: center;
          margin-bottom: 1.875rem;

          @include respond-min(tablet) {
            flex: 0 0 32%;
            font-size: 12px;
          }
          @include respond-min(desktop) {
            flex: 0 0 28%;
            font-size: inherit;
          }

          &:hover {
            background: $red2;
          }
        }

        .form__checkbox {
          margin-top: 15px;

          .input__label {
            color: rgba($color: $black-color1, $alpha: 0.6);
            font-size: 15px;
            font-weight: 400;
            line-height: 160%;
            padding-left: 0;

            &::before, &::after {
              border-radius: 0;
              display: none;
            }

            a {
              color: $black-color1;
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }

      & img {
        max-width: 50%;
        object-fit: cover;
        //transform: rotate(180deg) translate(15px, 35px);

        @include respond-min(tablet) {
          height: 100%;
          max-width: 100%;
          width: auto;
          transform: none;
        }
      }
    }
  }
}
