/* Custom variables */

:root {
  --scrollbar-width: 17px;
  --products-slider-columns: 3;
}

/* Viewports */
$mobile: 320px;
$mobile-md: 360px;
$mobile-ml: 425px;
$mobile-mx: 575px;
$tablet-sm: 768px;
$tablet-sl: 960px;
$tablet: 1024px;
$tablet-md: 1140px;
$desktop-sm: 1280px;
$desktop: 1440px;
$desktop-md: 1520px;
$desktop-lg: 1600px;
$desktop-xlg: 1920px;


/* Colors */

$black: #000000;
$black2: #0E0F11BF;
$white: #ffffff;
$pink: #f9d2df;
$pink1: #FFEBF2;
$p-red: #f9d2d2;
$l-red: #e8c9db;
$l-red1: #dd044b;
$red: #ad033b;
$red1: #de350b;
$red2: #D40046;
$red3: #D51148;
$scarlet: #e83872;
$d-red: #8c022f;
$orange: #ff8b00;
$l-orange: #ffab00;
$yellow: #f0eeb5;
$g-yellow: #f6e0bf;
$l-yellow: #f1e7cc;
$d-green: #52ae94;
$m-green: #0CB785;
$green: #23dea8;
$g-green: #d9e4ad;
$b-green: #3DEAB8;
$l-green: #c5efdd;
$l-green1: #57d9a3;
$w-green: #b5ebd4;
$l-blue: #ccdff1;
$l2-blue: #E5F7FF;
$s-blue: #7eb1e2;
$blue: #2363de;
$d-blue: #0759DE;
$l-grey: #edf1f2;
$grey: #d1d7db;
$grey-2: #CCCCCC;
$grey-3: #919AA6;
$grey-4: #F0F5F7;
$grey-5: #616161;
$grey-6: #3F444D;
$grey-7: #69717F;
$grey-8:#F5F8FB;
$grey-9:#F1F5F9;
$grey-10:#64708C;
$grey-11:#F3F6F9;
$grey-12: #D8DFE9;
$grey-13: #DFE8F1;
$grey-14: #EAEFF6;
$d-grey: #919aa6;
$md-grey: #4d4d4d;
$ed-grey: #303030;
$vd-grey: #626D7D;
$vd-grey1: #505f79;
$vd-grey2: #DFE8F1;
$text-color: #75808f;
$black-color1: #292D32;
$l-purple: #e7ccf1;
$purple: #d8ccf1;
$light-pink: #FFEBF2;
$graphite: #454F5F;
/* Body Size */
$body-size-mobile: 8;
$body-size-pc: 16;

/* Offsets */

$offset-mobile: 2.5rem;
$offset-desktop: 7.75rem;
$padding-blocks: 3.75rem;
// $offset-desktop: 17rem;


/* Transition */

$trs: all 0.4s cubic-bezier(0.33, 0, 0, 1);

//Возможно стоит перенести градиенты отдельно?
$gradient-gray-white: linear-gradient(279deg, #DAE5F4 -9.39%, #6A7993 109.51%);
$gradient-white-darkblue: linear-gradient(279deg, #C9D2E1 -9.39%, #E7EEF6 109.51%);
$gradient-white-blue: linear-gradient(106deg, #F3F6F9 -38.8%, #D8E3EE 125.16%);
$gradient-white-pink: linear-gradient(237deg, #FCD2DF -18.33%, #FFF0F5 129.43%);
$gradient-pink-darkblue: linear-gradient(283deg, #DBE5F0 -1.2%, #FFE2EC 104.28%);
$gradient-darkblue-red: linear-gradient(102deg, #CEDCEC 6.08%, #F0C9D6 68.89%);
$gradient-white-light-pink: linear-gradient(251deg, #FFEEF4 -19.14%, #F4F9FF 110.79%);
$gradient-darkblue-gray: linear-gradient(0deg, #B3C1D3 0%, #B3C1D3 100%), #70798B;
$gradient-violet-pink: linear-gradient(30deg, #282239 0%, #D82E67 100%);
