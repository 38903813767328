.page--main {
  .no-scroll {
    overflow: hidden;
    height: 100%;
    width: 100%;
    position: fixed;
  }
}
.new-header {
.main-nav {
  z-index: 1;
  display: none;
  flex-direction: column;
  flex-shrink: 0;
  max-height: none;

  .mobileapp-mainmenu-img {
    max-width: 280px;
  }
  .header.header--title & {
    @include respond-min(tablet-sm) {
      display: none;
    }
  }

  .mobileapp-mainmenu-img {
    max-width: 100%;
  }

  @include respond-min(desktop-sm) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: stretch;
  }

  .accordion__title {
    margin: 0 !important;
    
    @include respond-min(tablet-sm) {
      margin: 0;
      padding: 0 0.5rem 0 0;

      &::before, &:after {
        display: none;
      }
    }
    @include respond-min(desktop-sm) {
      padding: 0 .5rem 0 0;
    }
    &::before, &:after {
      display: none;
    }
  }

  &__login-block {
    margin: 30px 2.5rem 10px 2.5rem;
    border-bottom: 1px solid rgba($color: #8A8A8F, $alpha: 0.18);
    flex-direction: column;

    .open-account {
      background-color: $red;
      border-radius: 8px;
      padding: 1.6rem 2.5rem;
      width: fit-content;
      span.media-obj {
        height: 18px;
        width: 18px;
      }
      span {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: $white;
      }
    }

    a {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      text-decoration: none;

      img {
        margin-right: 10px;
      }
      .media-obj {
        height: 30px;
        width: 30px;
        margin-right: 10px;
      }
      span {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: rgba($color: $black, $alpha: 0.8);
      }
      .half-opacity {
        opacity: 0.5;
      }
    }
  }
}
.header.opened .main-nav {
  display: flex;
  max-height: none;
  width: 100%;
}
.header.opened .mobile-bottom-nav-container {
  display: none;

  @include respond-min(tablet-sm) {
    display: flex;
  }
  
  @include respond-min(desktop-sm) {
    display: none !important;
  }
}
.mobile-bottom-nav-container {
  display: none;
  flex-direction: column;
  width: 100%;
  margin-top: auto;
  gap: 32px;

  .mobile-nav-banner-container {
    gap: 12px;
    flex-direction: row;
    flex: 0 40%;
    padding: 0;
    display: none;
    /*transform: translate3d(0px, -3px, 0px) scale3d(0.8, 0.8, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
    transform-style: preserve-3d;*/
    opacity: 1;

    @include respond-min(tablet-sl) {
      gap: 16px;
    }
  
    .main-nav__banner-post {
      background-position: 0 0, 0 0, 50%;
      background-repeat: repeat, repeat, no-repeat;
      background-size: auto, auto, cover;
      border-radius: 10px;
      flex-direction: column;
      flex: 1;
      justify-content: flex-end;
      align-items: stretch;
      display: flex;
      overflow: hidden;
      text-decoration: none;
      height: 144px;
      
      .main-nav__banner-info {
        gap: 1em;
        color: #fff;
        flex-direction: column;
        flex: 0 auto;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        padding: 20px;
        transition: background-color .2s;
        display: flex;
        &:hover {
          background-color: rgba(0, 0, 0, .2);
        }
        .info-container {
          grid-column-gap: 1em;
          grid-row-gap: 1em;
          flex-direction: column;
          display: flex;
          .info-title {
            gap: 16px;
            flex-direction: column;
            align-items: flex-start;
            display: flex;
            .title-banner {
              background-color: $red;
              letter-spacing: 1px;
              text-transform: uppercase;
              border-radius: 4px;
              padding: 6px 12px;
              font-family: 'Montserrat', sans-serif;
              font-size: 10px;
              font-weight: 700;
              line-height: 14px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
              display: inline-block;
            }
            .title-text {
              margin-top: 0;
              margin-bottom: 0;
              font-family: 'Montserrat', sans-serif;
              font-size: 15px;
              font-weight: 600;
              line-height: 20px;
              letter-spacing: -0.005em;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
            }
          }
          .body-text {
            font-size: 1.125em;
          }
        }
      }
    }
  }
  .main-nav__phone-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #0E0F110D;
    padding: 15px 0;
    margin: 0 -16px;

    a {
      font-family: 'Montserrat', sans-serif;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      text-align: center;
      color: #0C0E11E5;
      letter-spacing: -0.005em;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      -webkit-background-clip: text;
      background-clip: text;
    }

    span {
      font-family: 'Montserrat', sans-serif;
      font-size: 10px;
      font-weight: 500;
      line-height: 14px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      letter-spacing: 0%;
      color: #0E0F1180;
    }
  }
}

.header.opened .main-nav__item {
  
  @include respond-min(tablet-sm) {
    position: relative;
  }

  @include respond-max(desktop-sm) {
    right: 0 !important;
  
    &:nth-of-type(1) {
      transition-delay: 0.2s;
    }

    &:nth-of-type(2) {
      transition-delay: 0.3s;
      .accordion__content {
        @include respond-min(tablet-sm) {
          top: -42px;
        }
      }
    }

    &:nth-of-type(3) {
      transition-delay: 0.4s;
      .accordion__content {
        @include respond-min(tablet-sm) {
          top: -84px;
        }
      }
    }
  }
}
.main-nav__list {
  display: flex;
  flex-direction: column;

  @include respond-min(tablet-sm) {
    gap: 12px;
  }

  @include respond-min(desktop-sm) {
    flex-direction: row;
  }

  @include respond-min(desktop-lg) {
    gap: 12px;
  }
}

.main-nav__item {
  position: relative;
  display: flex;
  flex-direction: column;
  border-bottom: 0;

  @include respond-max(desktop-sm) {
    right: -100%;

    /*.header.opened & {
      right: 0 !important;

      &:nth-of-type(1) {
        transition-delay: 0.2s;
      }

      &:nth-of-type(2) {
        transition-delay: 0.3s;
      }

      &:nth-of-type(3) {
        transition-delay: 0.4s;
      }
    }*/
  }

  .accordion__content {
    @include respond-min(tablet-sm) {
      position: absolute;
      left: 256px;
      max-height: 720px !important;
      width: calc(100vw - 288px);
      display: none !important;
    }
    @include respond-min(tablet-sl) {
      left: 272px;
      width: calc(100vw - 304px);
    }
    @include respond-min(desktop-sm) {
      //display: flex !important;
      position: unset;
      left: unset;
      width: initial;
      max-height: 100% !important;
    }
  }

  @include respond-min(desktop-sm) {
    overflow: hidden;
    position: static;
    flex-direction: row;
    border-bottom: 0;

    &:hover {
      overflow: visible;
    }
  }
  &.active, &.opened {
    .main-nav__link-triangle-red {
      transform: rotate(180deg);
      filter: none;
      @include respond-min(tablet-sm) {
        transform: rotate(-90deg);
      }
    }
    .main-nav__link {
      color: #0C0E11E5;
    }
    & > div {
      @include respond-min(tablet-sm) {
        background: #0E0F110D;
        border-radius: 4px;
      }
    }
    .accordion__content {
      @include respond-min(tablet-sm) {
        display: flex !important;
      }
      /*@include respond-min(tablet-sl) {
        width: calc(100vw - 304px);
      }*/
    }
  }
  & > div {
    display: flex;

    @include respond-min(tablet-sm) {
      width: 220px;
    }

    @include respond-min(desktop-sm) {
      width: auto;
    }

    @include respond-max(desktop-sm) {
      justify-content: space-between;
    }

    @include respond-min(desktop-lg) {
      gap: 8px;
    }
  }
}
.main-nav__link--no-accordion {

  @include respond-min(desktop-sm) {
    padding: 30px 8px !important;
  }
}

.main-nav__link, .main-nav__link--no-accordion {
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 14px 0 14px 14px;
  opacity: .75;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  letter-spacing: -0.005em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #0E0F11B2;
  background: transparent;
  font-size: 15px;
  line-height: 20px;
  
  @include respond-min(tablet-sm) {
    padding: 6px 8px !important;
    font-size: 13px;
    line-height: 18px;
  }

  @include respond-min(desktop-sm) { 
    //padding: 8px !important;
    background-color: $white;
    padding: 30px 0 30px 8px !important;
  }

  @include respond-min(desktop-lg) {
    font-size: 15px;
    line-height: 20px;
  }

  &:hover {
    opacity: 1;
  }
  &.full {
    width: 100%;
  }
  &.arrow-full {
    @include respond-max(desktop-sm) {
      width: calc(100% - 50px);
    }
  }

  .main-nav__content & {
    position: sticky;
    top: 0;
    margin-bottom: 3.75rem !important;
    padding-left: 5.5rem !important;
    border-bottom: 1px solid $black;
  }
/*
  @include respond-min(tablet-sm) {
    margin: 0 !important;
    padding: 1.75em 0 1.75em 1em;

    .main-nav__item:last-of-type & {
      padding-right: 1rem !important;
    }
  }*/
}
.main-nav__link--triangle {
  padding-right: 16px !important;
  padding-left: 0;

  @include respond-min(tablet-sm) {
    padding-left: 0;
  }

  @include respond-min(desktop-sm) {
    padding-right: 0 !important;
  }
}

.main-nav__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.7);
  bottom: auto;
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transform: translateX(0) scaleY(0);
  transform-origin: 50% 0;
  transition:
    opacity 0.4s cubic-bezier(0.33, 0, 0, 1) 0s,
    transform 0s cubic-bezier(0.33, 0, 0, 1) 0.4s;

  &.active {
    opacity: 1;
    pointer-events: all;
    transform: translateX(0) scaleY(1);
    transition-delay: 0s, 0s;
  }
}

.main-nav__link-triangle {
  position: absolute;
  top: 50%;
  right: 2.5rem;
  width: 0.625rem;
  height: 1.25rem;
  transform: translateY(-50%);

  &-red {
    height: 16px;
    width: 16px;
    margin-left: 20px;
    filter: grayscale(1);
    
    @include respond-min(tablet-sm) {
      margin-left: .5em;
      filter: none;
      transform: rotate(90deg);
    }
    
    @include respond-min(desktop-sm) {
      transform: rotate(0deg);
    }
    
    @include respond-min(desktop-lg) {
      margin-right: 8px;
      margin-left: 0;
    }
  }
  .main-nav__content & {
    left: 2.5rem;
    transform: translateY(-50%) rotate(180deg);
  }

  .main-nav__item.opened > .main-nav__link & {
    transform: translateY(-50%) scale(0) !important;
  }

  @include respond-min(tablet-sm) {
    right: 2rem;
    width: 0.6rem;
    height: 0.6rem;
    color: $d-grey;
    transform: translateY(-50%) rotate(225deg);

    .main-nav__item:last-of-type & {
      right: 0;
    }
  }

  svg {
    fill: currentColor;
  }
}

.main-nav__content {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  z-index: 2;
  top: calc(8rem + 1px);
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transform: translateX(-100%);
    
  background: $grey-8;
  box-shadow: 0px 50px 80px rgba(41, 45, 50, 0.18);

  .main-nav__item.opened & {
    opacity: 1 !important;
    transform: translateX(0) !important;
    pointer-events: all !important;
  }

  @include respond-min(tablet-sm) {
    position: absolute;
    top: calc(100% + 1px);
    bottom: auto;
    display: flex;
    flex-direction: column;
    opacity: 0;
    pointer-events: all;
    transform: translateX(0) scaleY(0);
    transform-origin: 50% 0;
    transition:
      opacity 0.4s cubic-bezier(0.33, 0, 0, 1) 0s,
      transform 0s cubic-bezier(0.33, 0, 0, 1) 0.4s;
  }

  @include respond-min(desktop-lg) {
    max-height: calc((100vh - 9.875rem) * 0.8); // максимум 80% от высоты экрана без хедера
  }

  &.only-desktop {
    gap: 0 !important;
  }
}

.main-nav__phone-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #0E0F110D;
  padding: 15px 0;
  margin: 0;

  a {
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    color: #0C0E11E5;
    letter-spacing: -0.005em;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    text-decoration: none;

    @include respond-min(desktop-xlg) {
      font-size: 15px;
      line-height: 20px;
    }
  }

  span {
    font-family: 'Montserrat', sans-serif;
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    letter-spacing: 0%;
    color: #0E0F1180;
  }
}

.main-nav__item.hovered {
  .main-nav__banner-container {
    @include respond-min(tablet-sm) {
      transform: translate3d(0px, 0em, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
      opacity: 1;
      transition: all .85s ease 0.4s;
    }
  }
  .main-nav__content {
    @include respond-min(desktop-sm) {
      opacity: 1;
      pointer-events: all;
      transform: translateX(0) scaleY(1);
      transition-delay: 0s, 0s;
    }
  }
}

.main-nav__submenu-container {
  background-color: #fff;
  display: flex;
  flex-direction: row;

  @include respond-max(tablet-sm) {
    background-color: #f4f4f5;
    box-shadow: inset 0 -1px 2px rgba(0, 0, 0, .1), inset 0 1px 1px rgba(0, 0, 0, .1);
  }

  @include respond-min(desktop-sm) {
    min-height: 27em;
  }

  .main-nav__sublink-container {
    flex-direction: column;
    flex: 1;
    display: flex;
    gap: 24px;
    padding: 10px 16px 16px;

    @include respond-min(tablet-sm) {
      width: 100%;
      min-width: 100%;
      gap: 20px;
      flex: 0 60%;
      flex-direction: row;
      padding: 0;
    }

    @include respond-min(desktop-sm) {
      width: auto;
      min-width: auto;
      padding: 24px;
      padding-right: 20px;
    }

    .main-nav__sublink-column {
      padding: 0;
      gap: 12px;
      display: flex;
      flex-direction: column;
      flex: 1;

      @include respond-min(tablet-sm) {
        flex: 0 33.33%;
        gap: 16px;
      }

      &.two {
        @include respond-min(tablet-sm) {
          flex: 0 50%;
        }
      }

      .column-title {
        font-family: 'Montserrat', sans-serif;
        font-size: 9px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 8%;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        min-height: 0 !important;
        color: #0E0F1180;
        text-transform: uppercase;
        background-position: 0%;
        background-repeat: no-repeat;
        background-size: 16px;
        padding-left: 24px;
        display: inline-block;

        @include respond-min(desktop-lg) {
          font-size: 10px;
          letter-spacing: 10%;
        }

        @include respond-min(desktop-xlg) {
          line-height: 15px;
        }

        &.services {
          background-image: url("../../../src/assets/sprite/i-menu-services.svg");
        }
        &.research {
          background-image: url("../../../src/assets/sprite/i-menu-research.svg");
        }
        &.stock {
          background-image: url("../../../src/assets/sprite/i-menu-stock.svg");
        }
        &.pifs {
          background-image: url("../../../src/assets/sprite/i-menu-pifs.svg");
        }
        &.capital {
          background-image: url("../../../src/assets/sprite/i-menu-capital.svg");
        }
        &.media {
          background-image: url("../../../src/assets/sprite/i-menu-media.svg");
        }
        &.solutions {
          background-image: url("../../../src/assets/sprite/i-menu-solutions.svg");
        }
        &.control {
          background-image: url("../../../src/assets/sprite/i-menu-control.svg");
        }
      }
      .column-link {
        width: 100%;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 4px;
        font-family: 'Montserrat', sans-serif;
  
        &:hover {
          background-color: #F3F3F3;
        }
        
        .link-title {
          font-size: 13px;
          font-weight: 600;
          line-height: 18px;
          text-align: left;
          letter-spacing: -0.5%;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #0C0E11E5;

          @include respond-min(desktop-xlg) {
            font-size: 15px;
            line-height: 20px;
          }
        }
        .link-description {
          color: #0E0F11B2;
          font-size: 10px;
          font-weight: 400;
          line-height: 14px;
          text-align: left;
          letter-spacing: 0%;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;

          @include respond-min(tablet-sl) {
            font-size: 11px;
            line-height: 15px;
          }

          @include respond-min(desktop-xlg) {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
  }
  .main-nav__banner-container {
    flex-direction: row;
    flex: 0 40%;
    display: flex;
    transform: translate3d(0px, -3px, 0px) scale3d(0.8, 0.8, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
    transform-style: preserve-3d;
    opacity: 0;
    padding: 24px;
    padding-left: 0;
    gap: 20px;

    .main-nav__banner-post {
      background-position: 0 0, 0 0, 50%;
      background-repeat: repeat, repeat, no-repeat;
      background-size: auto, auto, cover;
      border-radius: 10px;
      flex-direction: column;
      flex: 1;
      justify-content: flex-end;
      align-items: stretch;
      display: flex;
      overflow: hidden;
      text-decoration: none;

      @include respond-min(tablet-sm) {
        height: 144px;
      }

      @include respond-min(desktop-sm) {
        height: unset;
        border-radius: 16px;
      }
      .main-nav__banner-info {
        color: #fff;
        flex-direction: column;
        flex: 0 auto;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        padding: 20px;
        transition: background-color .2s;
        display: flex;

        @include respond-min(desktop-sm) {
          padding: 24px;
        }
        &:hover {
          background-color: rgba(0, 0, 0, .2);
        }
        .info-container {
          grid-column-gap: 1em;
          grid-row-gap: 1em;
          flex-direction: column;
          display: flex;
          .info-title {
            gap: 16px;
            flex-direction: column;
            align-items: flex-start;
            display: flex;
            
            @include respond-min(desktop) {
              gap: 20px;
            }
            .title-banner {
              background-color: $red;
              letter-spacing: 1px;
              text-transform: uppercase;
              border-radius: 4px;
              padding: 6px 12px;
              display: inline-block;
              font-family: 'Montserrat', sans-serif;
              font-size: 10px;
              font-weight: 700;
              line-height: 14px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;

              @include respond-min(desktop-sm) {
                font-size: 11px;
                line-height: 15.4px;
              }
            }
            .title-text {
              margin-top: 0;
              margin-bottom: 0;
              font-family: 'Montserrat', sans-serif;
              font-size: 15px;
              font-weight: 600;
              line-height: 20px;
              letter-spacing: -0.005em;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;

              @include respond-min(desktop-sm) {
                font-size: 17px;
                line-height: 24px;
              }

              @include respond-min(desktop) {
                font-size: 19px;
                line-height: 27px;
              }

              @include respond-min(desktop-xlg) {
                font-size: 24px;
                line-height: 30px;
              }
            }
          }
          .body-text {
            font-size: 1.125em;
          }
        }
      }
    }
  }
}
.main-nav__block {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 3.75rem;

  @include respond-min(tablet-sm) {
    width: 35%;
    min-height: 100%;
    margin-bottom: 0;
    padding-top: 3.25rem;
    padding-bottom: 2rem;
    padding-left: 7rem;
    padding-right: 2rem;
  }

  @include respond-min(desktop) {
    flex-grow: 1;
    padding-left: calc(50vw - #{$desktop} / 2 + 7rem);
  }

  &--four-columns {
    width: 100%;
    overflow-x: hidden;

    @include respond-min(tablet-sm) {
      width: 28%;
      padding-left: 4rem;
    }
    @include respond-min(desktop) {
      width: 8%;
      padding-left: calc(50vw - 640px - 4rem);
    }
    @include respond-min(desktop-lg) {
      width: 20%;
      padding-left: calc(50vw - #{$desktop} / 2 - 4rem);
    }
  }
  &--five-columns {
    overflow-x: hidden;

    @include respond-min(tablet-sm) {
      width: 20%;
      padding-left: 4rem;
    }
    @include respond-min(desktop) {
      width: 8%;
      padding-left: calc(50vw - 640px - 4rem);
    }
    @include respond-min(desktop-lg) {
      width: 20%;
      padding-left: calc(50vw - #{$desktop} / 2 - 4rem);
    }
  }
}

.main-nav__sublist {
  display: flex;
  flex-direction: column;
  margin-bottom: 3.75rem;
  padding-left: $offset-mobile;
  padding-right: $offset-mobile;

  .main-nav__banner & {
    padding-left: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
  &--mb1 {
    margin-bottom: 1.25rem;
  }
  &--bold {
    .main-nav__sublink {
      font-weight: 400;
      font-size: 14px;
      line-height: 120%;
      color: rgba($color: $black-color1, $alpha: 0.6);
      margin-bottom: 15px;
      
      @include respond-min(tablet-sm) {
          font-weight: 600;
          font-size: 16px;
          color: $black-color1;
          margin: 0;
      }
    }
  }
  .main-nav__sublist {
    padding-left: 0;
  }

  @include respond-min(tablet-sm) {
    margin-bottom: 2.5rem;
    padding-left: 0;
    padding-right: 0;

    &--mb1 {
      margin-bottom: 1rem;
    }
  }
}

.main-nav__subitem {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  & .main-nav__subtitle {
    margin-left: 0;
    text-decoration: none;
  }
  
  @include respond-min(tablet-sm) {
    margin-bottom: 1rem;
  }

  &:hover {
    opacity: 0.5;
  }
}

.main-nav__sublink {
  align-self: flex-start;
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  line-height: 1.4;
  text-decoration: none;

  @include respond-min(tablet-sm) {
    @include font('m');
    align-self: auto;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: rgba($black-color1, 0.75);

    &:hover {
      color: $black-color1;
    }
  }

  &--bold {
    
    @include respond-min(tablet-sm) {
      font-weight: 600;
      font-size: 16px;
      color: $black-color1;
    }
  }
}

.main-nav__subtitle {
  @include font('b');

  align-self: flex-start;
  margin-bottom: 2.5rem;
  //margin-left: $offset-mobile;
  margin-right: $offset-mobile;
  font-size: 1.375rem;
  line-height: 1.17;
  letter-spacing: normal;
  text-transform: none;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: $black-color1;
    
  &--bright {
    
    @include respond-min(tablet-sm) {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
  }
  .main-nav__banner & {
    margin-left: 0;
  }

  @include respond-min(tablet-sm) {
    margin-bottom: 1.25rem;
    margin-left: 0;
    margin-right: 0;
    font-size: 16px;
    letter-spacing: 0.08em;
  }
}

.main-nav__info {
  flex-shrink: 0;
  padding-top: 5rem;
  padding-bottom: 5rem;

  @include respond-min(tablet-sm) {
    width: 30%;
    min-height: 100%;
    padding-top: 3.25rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @include respond-min(desktop) {
    width: 26.75rem;
  }
  &--four-columns {
    width: 100%;
    overflow-x: hidden;
    
    @include respond-min(tablet-sm) {
      width: 20%;
    }
    @include respond-min(desktop) {
      width: 20%;
    }
  }
  &--five-columns {
    overflow-x: hidden;
    
    @include respond-min(tablet-sm) {
      width: 16%;
    }
    @include respond-min(desktop) {
      width: 12%;
    }
  }
}

.main-nav__banner {
  flex-shrink: 0;
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-left: $offset-mobile;
  padding-right: $offset-mobile;

  @include respond-min(tablet-sm) {
    width: 35%;
    min-height: 100%;
    padding-top: 3.25rem;
    padding-bottom: 2rem;
    padding-left: 3.25rem;
    padding-right: 10rem;
  }

  @include respond-min(desktop) {
    flex-grow: 1;
    padding-right: calc(50vw - #{$desktop} / 2 + 10rem);
  }  
  .banner-postimage {
    margin-bottom: 20px; 
    font-size: 16px; 
    text-align: end;
    max-width: 45rem;
    
    @include respond-min(tablet-sm) {
      max-width: none;
    }
  }
  ul {
    li {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
      color: rgba($color: #292D32, $alpha: 0.7);
      list-style: disc;
      margin-left: 17px;
    }
  }
  &--first {
    padding-bottom: 0;
  }
  &--four-columns {
    width: 100%;
    padding-right: 1rem;
    overflow-x: hidden;

    @include respond-min(tablet-sm) {
      width: 20%;
      padding-right: 1rem;
    }
    @include respond-min(desktop) {
      width: 20%;
      padding-right: 1rem;
    }  
  }
  &--five-columns {
    padding-right: 1rem;
    overflow-x: hidden;

    @include respond-min(tablet-sm) {
      width: 20%;
      padding-right: 1rem;
    }
    @include respond-min(desktop) {
      width: 20%;
      padding-right: 1rem;
    }  
  }
  &--less-padding {

    @include respond-min(tablet-sm) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .main-nav__phone-block {
      margin: 0;
    }
  }
  &:last-child {
    padding-right: 3.25rem;
    padding-left: 1rem;

    @include respond-min(desktop) {
      padding-right: 3.25rem;
      padding-left: 1rem;
    }
  }
}

.main-nav__banner-image {
  flex-shrink: 0;
  max-width: 45rem;
  margin-bottom: 2.5rem;
  border-radius: 8px;

  @include respond-min(tablet-sm) {
    height: 160px;
    width: 100%;
    max-width: 100%;
    margin-bottom: 1.875rem;

    img {
      object-position: top center;
      border-radius: 8px;
      object-fit: cover;
    }
  }
}

.main-nav__banner-title {
  margin-bottom: 3.75rem;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: rgba($black-color1, 0.7);

  @include respond-min(tablet-sm) {
    margin-bottom: 2.75rem;
  }
}

.main-nav__banner-over-title {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  color: rgba($black-color1, 0.6);
  margin-bottom: 15px;
  
  @include respond-min(tablet-sm) {
    min-height: 34px;
  }
}

.main-nav__btn-wrap:hover {
  
  .main-nav__banner-title, .main-nav__banner-over-title {
    color: $black-color1;
  }
}

.menu-advantages {
  &__item {
    line-height: 22px;
    font-size: 16px;
  }
}
.main-nav__info {
  .contacts-container {
    font-size: 16px;
    margin-left: 2.5rem;
    margin-right: 2.5rem;

    @include respond-min(tablet-sm) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
}